import React, { Component } from 'react';
import Banner from './component/Banner/Banner';
import FeaturedVideo from './component/FeaturedVideo/FeaturedVideo';
import Package from './component/Package/Package';
import Location from './component/Location/Location';
import Places from './component/TourPlaces/Places';
import TourPackage from './component/Package/TourPackage';
import Video from './component/Video/Video';
import Resturants from './component/Resturants/Resturants';
import Holiday from './component/Holiday/Holiday';
import Customer from './component/Customer/Customer';
import Feature from './component/Features/Feature';
import Blog from './component/Blog/Blog';
import Subscribe from './component/Subscribe/Subscribe';


class Home extends Component {
    render() {
        return (
            <div>
                <Banner />
                <FeaturedVideo />
                <Package />
                <Location />
                <Places />
                <TourPackage />
                <Video />
                <Holiday />
                <Resturants />
                <Customer />
                <Feature />
                <Blog />
                <Subscribe />
                {/* <LoggedInToast show={this.state.showToaster}/> */}

            </div>
        )
    }
}

export default Home


