import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Carousel } from 'react-responsive-carousel';

import moment from 'moment';

import { Breadcrumb, Badge, OverlayTrigger, Tooltip, ButtonGroup, ToggleButton, Toast } from 'react-bootstrap'

import Balcony from '../../assets/images/feature/balcony.svg';
import singleBed from '../../assets/images/icon/single-bed.svg';
import PlaceholderImg from '../../assets/images/placeholder.png';

import MapModalView from '../../component/Map/Map';
import SearchPanel from '../../component/SearchPanel/SearchPanel';

import { getHotelById, getInventoryByHotelId } from '../../store/actions/hotelActions/hotelActionCreators';
import { createBooking } from '../../store/actions/bookingActions/bookingActionCreators';


const Hotel = () => {
    const hotel = useSelector(state => state.hotelReducer);
    const { id } = useParams()
    const dispatch = useDispatch();
    const history = useHistory();

    const [margin, setMargin] = useState('mt-120')
    const [classSearch, setclassSearch] = useState('search-outer-wrapper')
    const [showMapModal, setShowMapModal] = useState(false);
    const selectRoomRef = useRef(null)

    const [searchDataList, setSearchDataList] = useState(JSON.parse(localStorage.getItem("searchItems")))
    const [showReservePrice, setShowReservePrice] = useState(false)
    const [showReserveDetail, setShowReserveDetail] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0);
    const [roomId, setRoomId] = useState([])
    const baseURL = process.env.REACT_APP_BASE_URL
    let vat = 12;
    let discount = 0;

    const fetchSearchData = () => {
        const searchData = JSON.parse(localStorage.getItem("searchItems"));
        setSearchDataList(searchData);
    }

    const handleMapModal = () => {
        setShowMapModal(true)
    }

    const reserveShowHandler = (selectedRoom, europe_plan, breakfast, e) => {
        dispatch({
            type: "RESERVE_CHECKED",
            value: e.target.checked,
            roomId: selectedRoom
        })
        setShowReservePrice(true);
        calculateReserve(parseFloat(europe_plan), parseFloat(breakfast), e.target.checked);
        setShowReserveDetail(true);
        let selectedId = [...roomId];
        selectedId.push(selectedRoom)
        setRoomId(selectedId);
    }

    const calculateReserve = (price, pricebreakfast, roomChecked) => {
        let subTotal = 0;
        if (roomChecked) {
            subTotal = price + pricebreakfast + totalPrice;
        } else {
            subTotal = totalPrice - pricebreakfast - price;

        }
        setTotalPrice(subTotal);
    }
    const hideMapModal = () => {
        setShowMapModal(false)
    }

    const getHotelData = () => {
        dispatch(
            getHotelById(id, (res, err) => {
                if (err) {
                    console.log(err);
                }
            }))
    }

    const fetchHotelInventory = () => {
        const data = {
            searchingList: searchDataList,
            hotel_id: id
        }
        dispatch(
            getInventoryByHotelId(data, (res, err) => {
                if (err) {
                    console.log(err);
                }
            }))
    }

    const onClickReserveHandler = () => {
        if (totalPrice < 1) {
            toast.error("Please select one room !!!")
        } else {
            const searchList = JSON.parse(localStorage.getItem("searchItems"));;
            const checkinDate = moment(searchList.checkin).format('YYYY-MM-DD');
            const checkOutDate = moment(searchList.checkout).format('YYYY-MM-DD');
            const hotelId = hotel.hotelDataByid[0].hotel_id;
            let moduleName = "";
            if (hotelId) {
                moduleName = "Hotel";
            }
            let data = roomId.map((r_id) => {
                return {
                    module_name: moduleName,
                    quantity: hotel.inventory.filter(inv => inv.checked).length,
                    check_in_date: checkinDate,
                    check_out_date: checkOutDate,
                    sub_total: totalPrice,
                    discount: discount,
                    tax: totalPrice * (vat/100),
                    company_id: Number(id),
                    inventory_id: r_id,
                    no_of_adult: searchList.adults,
                    no_of_child: searchList.childrens,
                }
            })

            let selectedModules = {
                modules: data
            }
            let acessToken = localStorage.getItem("accessToken");
            if (acessToken) {
                dispatch(createBooking(selectedModules, (res, err) => {
                    if (res) {
                        history.push('/booking')
                    }
                }))
            } else {
                history.push({
                    pathname: '/login',
                    state: history.location.pathname
                })
                
            }
        }

    }

    const scrollToReservation = () => {
        selectRoomRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
    }
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY === 0) {
                setclassSearch('')
                setMargin('mt-120')
            } else {
                setclassSearch('sticky-search')
                setMargin('mt-50')
            }
        });
    }, []);

    useEffect(() => {
        fetchSearchData()
        getHotelData()
        fetchHotelInventory()
    }, [])

    return (

        <div className="content-wrapper hotel-detail-wrapper">
            <div className={`search-outer-wrapper ${classSearch}`}>
                <div className="container">
                    <SearchPanel searchList={searchDataList} />
                </div>
            </div>
            <div className={`container ${margin}`}>
                {hotel.hotelDataByid && hotel.hotelDataByid.map(item =>
                    <>
                        <div className="breadcrumbs-wrap">
                            <Breadcrumb>
                                <li className="breadcrumb-item"><Link to='/home'>Home</Link></li>
                                <li className="breadcrumb-item"><Link to='/searchResult'>Search Result</Link></li>
                                <li className="breadcrumb-item active">{item.hotel_name}</li>
                            </Breadcrumb>
                        </div>
                        <div className="hotel-detail-content mt-40" key={item.hotel_id}>
                            <div className="hotel-title d-flex align-items-center">
                                <div className="heading-left d-flex align-items-center">
                                    <Badge variant="secondary">{item.hotel_star_rating}</Badge>
                                    <h3 className="mx-2">{item.hotel_name}</h3>
                                    <div className="star-rating">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 10 10"><defs></defs><path className="star" d="M9.974,4.27a.536.536,0,0,0-.458-.381L6.63,3.615,5.489.828a.523.523,0,0,0-.977,0L3.37,3.615.483,3.889a.537.537,0,0,0-.457.381.57.57,0,0,0,.155.589l2.182,2L1.72,9.813a.567.567,0,0,0,.207.573.51.51,0,0,0,.584.027L5,8.86l2.489,1.553a.511.511,0,0,0,.584-.027.567.567,0,0,0,.207-.573L7.636,6.856l2.182-2a.571.571,0,0,0,.156-.59Zm0,0" transform="translate(0 -0.491)" /></svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 10 10"><defs></defs><path className="star" d="M9.974,4.27a.536.536,0,0,0-.458-.381L6.63,3.615,5.489.828a.523.523,0,0,0-.977,0L3.37,3.615.483,3.889a.537.537,0,0,0-.457.381.57.57,0,0,0,.155.589l2.182,2L1.72,9.813a.567.567,0,0,0,.207.573.51.51,0,0,0,.584.027L5,8.86l2.489,1.553a.511.511,0,0,0,.584-.027.567.567,0,0,0,.207-.573L7.636,6.856l2.182-2a.571.571,0,0,0,.156-.59Zm0,0" transform="translate(0 -0.491)" /></svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 10 10"><defs></defs><path className="star" d="M9.974,4.27a.536.536,0,0,0-.458-.381L6.63,3.615,5.489.828a.523.523,0,0,0-.977,0L3.37,3.615.483,3.889a.537.537,0,0,0-.457.381.57.57,0,0,0,.155.589l2.182,2L1.72,9.813a.567.567,0,0,0,.207.573.51.51,0,0,0,.584.027L5,8.86l2.489,1.553a.511.511,0,0,0,.584-.027.567.567,0,0,0,.207-.573L7.636,6.856l2.182-2a.571.571,0,0,0,.156-.59Zm0,0" transform="translate(0 -0.491)" /></svg>
                                    </div>
                                </div>
                                <div className="heading-right ml-auto">
                                    <button className="btn btn-primary px-3 py-2" onClick={scrollToReservation}>Reserve</button>
                                </div>
                            </div>
                            <div className="d-flex">
                                <span className="icon pr-2" onClick={handleMapModal}><i className="fas fa-map-marked-alt"></i></span>
                                <MapModalView
                                    show={showMapModal}
                                    hide={hideMapModal} longitude={item.hotel_longitude} latitude={item.hotel_latitude} />
                                <p><span>{item.hotel_address}, </span><span>{item.hotel_city} </span><span>{item.hotel_country} </span></p>
                                <p className="contacts">{item.hotel_phone_one} | {item.hotel_phone_two}</p>
                            </div>
                            <div className="hotel-gallery mt-4">
                                <Carousel infiniteLoop={true}>
                                    {item && item.hotel_gallery && item.hotel_gallery.map((img, index) => (
                                    <div>
                                       { img.image ? <img src={`${baseURL}${img.image}`} alt="hotel" className="img-fluid"/> : <img src={PlaceholderImg} alt="placeholder" className="img-fluid"/>}
                                        <p className="legend">{img.title}</p>
                                    </div>
                                    ))}
                                </Carousel>
                            </div>
                            <div className="hotel-info mt-4">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="bg-white hotel-description p-3">
                                            <div className="heading mb-3">
                                                <h5>Description</h5>
                                            </div>
                                            <p>{item.hotel_description}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="hotel-feature bg-white p-3">
                                            <div className="heading mb-3">
                                                <h5>Features</h5>
                                            </div>
                                            <div className="feature-wrapper d-flex">
                                                {item.hotel_facilities.map((facility, i) => (
                                                    <div className="features-item" key={i}>
                                                        <img src={`${baseURL}${facility.image}`} className="img-fluid" alt={facility.name} />
                                                        <p>{facility.name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="language-wrap mt-4">
                                                <div className="heading mb-3">
                                                    <h5>Language</h5>
                                                </div>
                                                <ul className="features-others mt-3">
                                                    {item.hotel_languages.map((lang, index) => (
                                                        <li className="mb-1 d-flex" key={index}>{lang.name}</li>
                                                    ))}

                                                </ul>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="choose-room mt-4" id="selectRoom">
                                <h4 ref={selectRoomRef}>Select your room</h4>
                                <div className="choose-room-wrapper mt-4">
                                    <div className="row">
                                        <div className="col-lg-9">

                                            {hotel.inventory && hotel.inventory.map(inv =>
                                                <div className="choose-room-item bg-white" key={inv.inventory_id}>

                                                    <div className="choose-room-wraps d-flex">
                                                        <div className="side-room-wraps">
                                                            <h5>{inv.inventory_name}</h5>
                                                            <small>Price for upto: <span className="bold">{inv.no_of_adult + inv.no_of_child} <i className="fas fa-user-alt"></i></span></small>
                                                            <p className="mt-2">{inv.inventory_description}</p>

                                                            {inv.inventory_type ?
                                                                <div className="bed-btns d-flex align-items-center mt-3">
                                                                    <div className="bed-img">
                                                                        <img src={singleBed} className="img-fluid" alt="feature" />
                                                                    </div>
                                                                    <div className="bed-text">
                                                                        <small>{inv.inventory_type}</small>
                                                                    </div>
                                                                </div> : <div></div>
                                                            }
                                                        </div>
                                                        <div className="reserve-part ml-auto text-right">
                                                            <h5>{inv.inventory_european_plan}</h5>
                                                            <small>per room per night</small>
                                                            <ButtonGroup toggle className="mb-2">
                                                                <ToggleButton
                                                                    type="checkbox"
                                                                    variant="success"
                                                                    checked={inv.checked}
                                                                    onChange={reserveShowHandler.bind(null, inv.inventory_id, inv.inventory_european_plan, inv.inventory_bed_and_breakfast_plan)}
                                                                >
                                                                    {inv.checked ? 'Reserved' : 'Reserve'}</ToggleButton>
                                                            </ButtonGroup>
                                                            {/* <button className="btn btn-primary" onClick={reserveShowHandler.bind(null, inv.inventory_id, inv.inventory_european_plan, inv.inventory_bed_and_breakfast_plan)}>Reserve</button> */}
                                                            <p>Includes taxes and charges</p>
                                                        </div>
                                                    </div>

                                                    <div className="choose-bed mt-3">
                                                        <div className="d-flex room-structure">
                                                            <p>Room Size: <span>{inv.inventory_size}</span> </p>
                                                            <p>Room: <span>{inv.no_of_room}</span> </p>
                                                            <p>Floor: <span>{inv.inventory_location}</span> </p>
                                                        </div>
                                                        {inv.inventory_bed_and_breakfast_plan &&
                                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Tooltip!</Tooltip>}>
                                                                <p className="for-breakfast mt-2"> Breakfast Included</p>
                                                            </OverlayTrigger>


                                                        }
                                                    </div>
                                                    <div className="features mt-3">
                                                        <div className="ratings-wrap d-flex align-item-center">
                                                            <h6>User Rating: </h6>
                                                            <badge className="badge badge-success ml-2 d-flex align-items-center px-2 py-1">{inv.inventory_rating_by_user}</badge>
                                                        </div>
                                                    </div>

                                                    <div className="features mt-3">
                                                        <h6>Our Features</h6>
                                                        <div className="features-content d-flex mt-3">
                                                            {inv.inventory_feature.map((feature, index) =>
                                                                <div className="features-item" key={index}>
                                                                    <img src={Balcony} className="img-fluid" alt="feature" />
                                                                    <p>{feature.name}</p>
                                                                </div>
                                                            )
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="features mt-3">
                                                        <h6>Facility</h6>
                                                        <ul className="features-others d-flex mt-3">
                                                            {inv.inventory_facilities.map((facility, index) =>
                                                                <li key={index}>{facility.name}</li>
                                                            )}
                                                        </ul>
                                                    </div>

                                                </div>
                                            )}
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="sticky-reserve-detail">
                                                {showReservePrice ?
                                                    <div className="reserve-price">
                                                        <small>1 room for</small>
                                                        <h3>NPR {totalPrice}</h3>
                                                        <small>Breakfast included</small>
                                                    </div> : <></>
                                                }
                                                <button className="btn btn-primary mt-10" onClick={onClickReserveHandler}>I'll Reserve</button>
                                                {showReserveDetail ?
                                                    <div className="reserve-detail">
                                                        <p> You'll be taken to the next step</p>
                                                        <ul>
                                                            <li>No registration required</li>
                                                            <li>Confirmation is immediate</li>
                                                        </ul>
                                                        <div className="your-package">
                                                            Your package:
                                                            Good breakfast included
                                                            FREE cancellation before 18:00 on 11 April 2021
                                                            NO PREPAYMENT NEEDED – pay at the property
                                                        </div>
                                                    </div> : <></>
                                                }

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                />
            </div>
        </div>
    )
}
export default Hotel;
