/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Select } from 'antd';
import Logo from '../../../assets/images/logo1.png';
const { Option } = Select;

function handleChange(value) {
    // console.log(`selected ${value}`);
}
class Footer extends Component {
    render() {
        return (
            // Footer 
            <section>
                <div className="footer">
                    <div className="footer-top py-4">
                        <div className="container">
                            <div className="row align-items-cente">
                                <div className="col-md">
                                    <ul className="d-flex">
                                        <li>
                                            <a href="#"><i className="fa fa-cc-amex"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"> <i className="fa fa-cc-visa"></i> </a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa fa-credit-card-alt"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fab fa-cc-mastercard"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa fa-cc-paypal"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa fa-cc-discover"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fa fa-google-wallet"></i></a>
                                        </li>
                                    </ul>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                    <div className="footer-main">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-3 col-md-6">
                                    <div className="travel-footer-des">
                                        <img src={Logo} className="img-fluid" alt="logo" />
                                        <p className="mb-1 mt-2 text-white">At vero eos et accusamus et iusto odio dignissimos ducimus voluptatum</p>
                                        <div className="social-links mt-4 mb-2">
                                            <h6>Social Icons</h6>
                                            <ul className="d-flex mt-2">
                                                <li>
                                                    <a href="#"><i className="fa fa-facebook"></i></a>
                                                </li>
                                                <li>
                                                    <a href="#"><i className="fa fa-twitter"></i></a>
                                                </li>
                                                <li>
                                                    <a href="#"><i className="fa fa-rss"></i></a>
                                                </li>
                                                <li>
                                                    <a href="#"><i className="fa fa-youtube"></i></a>
                                                </li>
                                                <li>
                                                    <a href="#"><i className="fa fa-linkedin"></i></a>
                                                </li>
                                                <li>
                                                    <a href="#"><i className="fa fa-google-plus"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 col-sm-6">
                                    <div className="travel-footer-des">
                                        <h6>Popular Tour Places</h6>
                                        <ul className="footer-links mb-0">
                                            <li>
                                                <a href="#"> <i className="fa fa-angle-double-right mr-2 text-secondary"></i> Canada Navy City</a>
                                            </li>
                                            <li>
                                                <a href="#"> <i className="fa fa-angle-double-right mr-2 text-secondary"></i>  Watican Flower Park</a>
                                            </li>
                                            <li>
                                                <a href="#"> <i className="fa fa-angle-double-right mr-2 text-secondary"></i> Paris Effil Tower</a>
                                            </li>
                                            <li>
                                                <a href="#"> <i className="fa fa-angle-double-right mr-2 text-secondary"></i> London Stone Bridegs</a>
                                            </li>
                                            <li>
                                                <a href="#"> <i className="fa fa-angle-double-right mr-2 text-secondary"></i>Best Summer Camps</a>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 col-sm-6">
                                    <div className="travel-footer-des">
                                        <h6>Useful Links</h6>
                                        <ul className="footer-links mb-0">
                                            <li>
                                                <a href="#"> <i className="fa fa-angle-double-right mr-2 text-secondary"></i>Our Team</a>
                                            </li>
                                            <li>
                                                <a href="#"> <i className="fa fa-angle-double-right mr-2 text-secondary"></i>Contact Us</a>
                                            </li>
                                            <li>
                                                <a href="#"> <i className="fa fa-angle-double-right mr-2 text-secondary"></i>About Us</a>
                                            </li>
                                            <li>
                                                <a href="#"> <i className="fa fa-angle-double-right mr-2 text-secondary"></i>Recent News</a>
                                            </li>
                                            <li>
                                                <a href="#"> <i className="fa fa-angle-double-right mr-2 text-secondary"></i>Terms and Services</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 col-sm-6">
                                <div className="travel-footer-des">
                                <h6>Get in Touch</h6>
                                        <ul className="footer-links touch-links mb-0">
                                            <li>
                                                <a href="#"> <i className="fa fa-home mr-3 text-secondary"></i>New York, NY 10012, US-52014</a>
                                            </li>
                                            <li>
                                                <a href="#"> <i className="fa fa-envelope mr-3 text-secondary"></i>info12323@example.com</a>
                                            </li>
                                            <li>
                                                <a href="#"> <i className="fa fa-phone mr-3 text-secondary"></i>+ 01 234 567 88, + 01 234 567 88</a>
                                            </li>
                                            <li>
                                                <a href="#"> <i className="fa fa-print mr-3 text-secondary"></i> + 01 234 567 89, + 01 234 567 89</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom py-4">
                        <div className="container">
                            <div className="footer-bottom-inner text-center">
                                <p> Copyright © 2020 <a href="#" className="text-secondary">Gowell</a>. Designed by <a href="#" className="text-secondary">Spruko</a> All rights reserved. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            // Footer  Ends
        )
    }
}

export default Footer
