import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Redirect, useHistory } from "react-router";
import { ToastContainer, toast } from 'react-toastify';


import { Form, Button, Col, Spinner, Alert } from 'react-bootstrap';
import { loginUser } from '../../../store/actions/authActions/authActionCreators';


const Login = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authReducer);

    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('')
 
    const [serverMessage, setServerMessage] = useState(false)

    const onSubmitHandler = () => {
        let eError = false;
        let pError = false;
        
        if(email.length === 0 ){
            setEmailError("Email is required");
            eError = true;
        }else{
            setEmailError(false)
            eError = false;

        }
        if(password.length === 0){
            setPasswordError("Password is required");
            pError = true;

        }else{ 
            setPasswordError(false)
            pError = false;

        }
       if(!pError && !eError){
        dispatch(
            loginUser({ email, password }, (res, err) => {
                if (err) {
                   if(err.response && err.response.data && err.response.data.email) {
                       setServerMessage(err.response.data.email);
                   }
                   if(err.response && err.response.data && err.response.data.non_field_errors){
                        setServerMessage(err.response.data.non_field_errors);
                   }
                      
                }
                if (res) {
                    toast.success("Login Success")
                    setTimeout(() => {
                        if(history.location.state){
                            history.push(history.location.state);
                        }else{
                        history.push("/");
                        }
                    }, 2000);
                }
            })
        );
       }
    }

    if (localStorage.getItem("accessToken") !== null) {
        return <Redirect to="/" />;
    }
    return (
        <div className="authenticate">
            <div className="form-main-wrapper">
                <div className="form register-form">
                    <div className="head text-center mb-4">
                        <h3>Login Form</h3>
                        <p>Doesn't have an account yet? <Link to="/register"><span>Register</span></Link> </p>
                    </div>
                    <Form >
                    { serverMessage && <Alert variant="danger">{serverMessage}</Alert> }
                        <Form.Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control className={emailError ? 'form-error' : ''} type="email" placeholder="Enter email" name="" value={email} 
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        setEmailError(false)
                                        setServerMessage(false)
                                    }} 
                                />
                                { emailError &&  <div className="text-danger"> {emailError}</div>}
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label>Password</Form.Label>
                                <Form.Control className={passwordError ? 'form-error' : ''} type="password" placeholder="Enter Password" value={password} onChange={(e) => {setPassword(e.target.value); setPasswordError(false);  setServerMessage(false)}} />
                                { passwordError &&  <div className="text-danger">{passwordError} </div>}
                           
                            </Form.Group>
                        </Form.Row>
                        <Form.Group controlId="formBasicCheckbox">
                            <Button variant="primary" className="w-auto px-5 py-2" onClick={onSubmitHandler}>
                                Submit  
                                {user.loginLoading &&
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                    />}
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
                <div className="other-media mt-4 text-center">
                    <p>or register with </p>
                    <div className="d-flex mt-4">
                        <div className="medias media-g">
                            <Button variant="outline-primary"><i className="fab fa-twitter"></i> Google</Button>
                        </div>
                        <div className="medias media-f">
                            <Button variant="outline-primary"><i className="fab fa-facebook-f"></i> Facebook</Button>
                        </div>

                    </div>

                </div>
            </div>
            <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                />
        </div>
        
    )

}

export default Login;