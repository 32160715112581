import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { createStore, applyMiddleware, compose} from 'redux';
import { createStore, applyMiddleware} from 'redux';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import thunk from 'redux-thunk';
import { rootReducer } from './store/reducers/rootReducer';

import App from './App';


// const composeEnhancers =
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true }) ||
//   compose;
const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
  // composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(

  <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
