/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faShieldAlt, faHeadphones } from '@fortawesome/free-solid-svg-icons';


class FeatureCard extends Component {
    render() {
        return (
            <div className="row"> 
                <div className="col-lg-4 col-md-6">
                    <div className="card mb-0">
                        <div className="feature-inner p-4 pt-4 text-center">
                                <div className="feature-img m-auto">
                                    <FontAwesomeIcon icon={faThumbsUp} className="text-primary" />
                                </div>
                                <h4 className="mt-5">Best Quality Services</h4>
                                <p className="text-muted mt-2">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="card mb-0">
                        <div className="feature-inner p-4 pt-4 text-center">
                                <div className="feature-img m-auto bg-success-transparent">
                                    <FontAwesomeIcon icon={faShieldAlt} className="text-success" />
                                </div>
                                <h4 className="mt-5">Travellers Security</h4>
                                <p className="text-muted mt-2">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12">
                <div className="card mb-0">
                    <div className="feature-inner p-4 pt-4 text-center">
                        <div className="feature-img m-auto bg-warning-transparent">
                            <FontAwesomeIcon icon={faHeadphones} className="text-warning" />
                        </div>
                        <h4 className="mt-5">24/7 Support</h4>
                        <p className="text-muted mt-2">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium</p>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

export default FeatureCard;
