import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Button, Col, Alert, Spinner } from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router";


import { Link } from "react-router-dom";
import { registerUser } from '../../../store/actions/authActions/authActionCreators';


const Register = () => {
    const user = useSelector((state) => state.authReducer);
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();


    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('')
    const [serverMessage, setServerMessage] = useState('')

    const onSubmitHandler = () => {
        let eError = false;
        let pError = false;

        if (email.length === 0) {
            setEmailError("Email is required");
            eError = true;
        } else {
            setEmailError(false)
            eError = false;

        }
        if (password.length === 0) {
            setPasswordError("Password is required");
            pError = true;

        } else {
            setPasswordError(false)
            pError = false;

        }
        if (!pError && !eError) {
            let data = {
                email: email, 
                password:password
            }
            dispatch(
                registerUser(data, (res, err) => {
                    console.log("res", res)
                    if (res) {
                        setEmail('');
                        setPassword([]);
                        toast.success("Successfully Registered")
                        setTimeout(() => {
                            history.push("/login");
                        }, 2000);
                    }else{
                        toast.error("Something went wrong !!!!")
                    }
                    if (err) {
                        if (err.response && err.response.data && err.response.data.email) {
                            setServerMessage(err.response.data.email);
                        }
                    }
                })
            )
        }
    }

    return (
        <div className="authenticate">
            <div className="form-main-wrapper">
                <div className="form register-form">
                    <div className="head text-center mb-4">
                        <h3>Register Form</h3>
                        <p>Already have an account? <Link to="/login"><span>Log in</span></Link></p>
                    </div>
                    <Form >
                        {serverMessage && <Alert variant="danger">{serverMessage}</Alert>}
                        <Form.Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" className={emailError ? 'form-error' : ''} placeholder="Enter email" value={email} onChange={(e) => {
                                    setEmail(e.target.value)
                                    setEmailError(false)
                                    setServerMessage(false)

                                }} />
                                {emailError && <div className="text-danger"> {emailError}</div>}
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label>Password</Form.Label>
                                <Form.Control className={passwordError ? 'form-error' : ''} type="password" placeholder="Enter Password" value={password} onChange={(e) => {
                                    setPassword(e.target.value)
                                    setPasswordError(false)
                                    setServerMessage(false)

                                }} />
                                {passwordError && <div className="text-danger"> {passwordError}</div>}
                            </Form.Group>
                        </Form.Row>
                        <Form.Group controlId="formBasicCheckbox">
                            <Button variant="primary" className="w-auto px-5 py-2 d-flex align-items-center justify-content-center"   
                               
                                onClick={onSubmitHandler}
                                >Submit  {user.registerLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="ml-2"
                                    />
                                    } </Button>
                        </Form.Group>
                    </Form>
                </div>
                <div className="other-media mt-4 text-center">
                    <p>or register with </p>
                    <div className="d-flex mt-4">
                        <div className="medias media-g">
                            <Button variant="outline-primary"><i className="fab fa-twitter"></i> Google</Button>
                        </div>
                        <div className="medias media-f">
                            <Button variant="outline-primary"><i className="fab fa-facebook-f"></i> Facebook</Button>
                        </div>

                    </div>

                </div>

            </div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
            />
        </div>
    )

}

export default Register;