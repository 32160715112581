import React from 'react';
import { Modal, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';

const BookingSuccessModal = (props) => {
  return (
    <Modal show={props.show} centered className="booking-success p-4">
      <Modal.Body className="text-center p-4">
        <div className="success-circle bg-success">
          <i className="fas fa-clipboard-check"></i>
        </div>
        <h4 className="text-success mb-3">Booking SuccessFully</h4>
        <Link to="/searchresult" className="text-white"><Button variant="primary" className="mt-3">For Booking More Hotels</Button></Link>
      </Modal.Body>
    </Modal>
  )
}

export default BookingSuccessModal;
