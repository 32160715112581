/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faStarHalfAlt, faStar, faEye } from '@fortawesome/free-solid-svg-icons';

import Place from '../../assets/images/holiday.png';
import person from '../../assets/images/customers.jpg';


class HolidayCard extends Component {
  render() {

    return (
      <div className="cards-layer">
          <Card>
            <a href="#" className="card-link-overlay"> <span className="hidden">link</span></a>
            
            <div className="card-img-wrap">
              <Card.Img variant="top" src={Place} />
              <div className="holiday-cities">
                <small className="views-wrap text-white"> <FontAwesomeIcon icon={faMapMarkerAlt} /> 8 Cities<FontAwesomeIcon icon={faEye} className="ml-3" /> 140+ Tour Places </small>
              </div>
              <div className="title-destination bg-info">
                <span>paris</span>
              </div>
            </div>
            
            <Card.Body>
              <div className="header-wrap d-flex">
                <Card.Title className="mb-2">London Tour
                <small className="text-muted mt-2">China, China Wall, Cities</small>
                </Card.Title>
                <div className="rating-wrap d-flex mb-3  ml-auto">
                    <div className="rating-inner mr-2">
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStarHalfAlt} />
                    </div>
                </div>
              </div>
              <Card.Text className="card-body-text mt-2">
                 <span className="days">12 Days,</span> <span className="time">12 Nights</span> Travel Trip
              </Card.Text>
            </Card.Body>
            <Card.Footer className="d-flex align-items-center">
              <div className="persona d-flex">
                  <div className="img-wrap mr-2">
                    <img src={person} alt="person" className="img-fluid"/>
                  </div>
                  <h5 className="user-title text-muted mt-2 mb-0">Elizabeth<i className="far fa-check-circle text-success pl-2"></i></h5>
              </div>
              <div className="love ml-auto">
                <i className="fas fa-heart text-heart"></i>
              </div>

            </Card.Footer>
          </Card>
        </div> 
    )
  }
}

export default HolidayCard;
