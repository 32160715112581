
export const LOADING_AUTH = "LOADING_AUTH";

export const LOADING_REGISTER = "LOADING_REGISTER";
export const LOADING_REGISTER_SUCCESS = "LOADING_REGISTER_SUCCESS"
export const LOADING_REGISTER_ERROR = "LOADING_REGISTER_ERROR"


export const LOADING_LOGIN = "LOADING_LOGIN";
export const LOADING_LOGIN_SUCCESS = "LOADING_LOGIN_SUCCESS";
export const LOADING_LOGIN_ERROR = "LOADING_LOGIN_ERROR";

export const LOGOUT = "LOGOUT";
