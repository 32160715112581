export const  LOADING_HOTEL ="LOADING_HOTEL";
export const  LOADING_HOTEL_SUCCESS ="LOADING_HOTEL_SUCCESS";
export const  LOADING_HOTEL_ERROR ="LOADING_HOTEL_ERROR";

export const  GET_FILTER_HOTEL ="GET_FILTER_HOTEL";
export const  GET_FILTER_HOTEL_SUCCESS ="GET_FILTER_HOTEL_SUCCESS";
export const  GET_FILTER_HOTEL_ERROR ="GET_FILTER_HOTEL_ERROR";

export const  SEARCH_FILTER_HOTEL ="SEARCH_FILTER_HOTEL";
export const  SEARCH_FILTER_HOTEL_SUCCESS ="SEARCH_FILTER_HOTEL_SUCCESS";
export const  SEARCH_FILTER_HOTEL_ERROR ="SEARCH_FILTER_HOTEL_ERROR";

export const  LOADING_HOTEL_BY_ID ="LOADING_HOTEL_BY_ID";
export const  LOADING_HOTEL_BY_ID_SUCCESS ="LOADING_HOTEL_BY_ID_SUCCESS";
export const  LOADING_HOTEL_BY_ID_ERROR ="LOADING_HOTEL_BY_ID_ERROR";

export const  LOADING_INVENTORIES ="LOADING_INVENTORIES";
export const  LOADING_INVENTORIES_SUCCESS ="LOADING_INVENTORIES_SUCCESS";
export const  LOADING_INVENTORIES_ERROR ="LOADING_HOTEL_BY_ID_ERROR";