/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faCalendarAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import Background from '../../assets/images/blog.jpg';

class BlogCard extends Component {
    render() {
        return (
            <div className="col-xl-4 col-lg-3 col-md-6">
                    <div className="card tour-place-card bg-cover" style={{ background: `url(${Background})` }}>
                        <a href="#" className="card-link-overlay"> <span className="hidden">link</span></a>
                        <div className="card-body">
                            <a href="blog-details.html" className="d-block mb-2">New Trip With Gowell</a>
                            <small className="text-warning"><FontAwesomeIcon icon={faTag} className="mr-1"/>Blog</small>
                            <small className="text-white pl-4"><FontAwesomeIcon icon={faCalendarAlt} className="mr-1"/>  27 Jul 2020</small>
                            <small className="text-white pl-4"><FontAwesomeIcon icon={faUser} className="mr-1"/> John Smith </small> 
                        </div>
                    </div>
            </div>
        )
    }
}

export default BlogCard;
