import * as paymentActionTypes from './paymentActionTypes';
import axios from '../../../helpers/axios';

export const checkPointsRequest = (cb) =>{
    return async(dispatch, getState) => {
        try{
            dispatch({
                type: paymentActionTypes.LOADING_CHECK_POINTS
            })
            const response = await axios.get(`/my_points/`,  {
                headers: {
                    Authorization: `Token ${getState().authReducer.token}`,
                },
            });
            if(response.status === 200){
                dispatch({
                    type: paymentActionTypes.LOADING_CHECK_POINTS_SUCCESS,
                    points:  response.data.points,
                })
                cb(response.data.points, false)
            }else{
                dispatch({
                    type: paymentActionTypes.LOADING_CHECK_POINTS_ERROR
                })
                cb(false, true)
            }
        }catch(err){
            dispatch({
                type: paymentActionTypes.LOADING_CHECK_POINTS_ERROR
            })
            cb(false, err)
        }


    }

}


export const buildHotelRequest = (payment) => {
    const formData = new FormData();
    formData.append('payment_method', payment.method)
    formData.append('payment_type', payment.pType)
    formData.append('payment_status', payment.pStatus)
    formData.append('total_price', payment.totalPrice)
    formData.append('total_discount', payment.discount)
    formData.append('total_tax', payment.tax)
    formData.append('booking_id', payment.bookingId)
    return formData;
};


export const createStorePayment = (data, cb) =>{
    const responseData = buildHotelRequest(data);
    return async(dispatch, getState) => {
        try{
            dispatch({
                type: paymentActionTypes.LOADING_STORE_PAYMENT
            })
            const response = await axios.post(`/store_payment/`, responseData,  {
                headers: {
                    Authorization: `Token ${getState().authReducer.token}`,
                },
            });
            if(response.status === 200){
                dispatch({
                    type: paymentActionTypes.LOADING_STORE_PAYMENT_SUCCESS,
                    storePayment:  response.data,
                })
                cb(true, false)
            }else{
                dispatch({
                    type: paymentActionTypes.LOADING_STORE_PAYMENT_ERROR
                })
                cb(false, true)
            }
        }catch(err){
            dispatch({
                type: paymentActionTypes.LOADING_STORE_PAYMENT_ERROR
            })
            cb(false, err)

        }

    }

}
