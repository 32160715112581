import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getHotelById } from '../../../store/actions/hotelActions/hotelActionCreators';
import moment from 'moment';
const baseURL = process.env.REACT_APP_BASE_URL


const BookingDetail = () => {
    const bookings = useSelector(state => state.bookingReducer)
    const dispatch = useDispatch();
    const [reserveHotelDetail, setReserveHotelDetail] = useState([])
    const [reserveDetail, setReserveDetail] = useState([])

    const hotelId = reserveHotelDetail && reserveHotelDetail[0] && reserveHotelDetail[0].hotel_id;
    console.log("hotelId", hotelId)

    console.log("reservedetail", reserveDetail)
    const getHotelData = () => {
        if (hotelId) {
            dispatch(
                getHotelById(hotelId, (res, err) => {

                    if (res) {
                        setReserveHotelDetail(res.data.hotel.data)
                    }
                    if (err) {
                        console.log(err);
                    }
                }))
        }
    }
    const reserveDetailData = () => {
        setReserveDetail(bookings.bookingData.modules)
    }
    useEffect(() => {
        getHotelData();
        reserveDetailData();
    }, [])
    return (
        <div className="filter-sidebar booking-details-bar">
            <div className="details-card">
                <h3 className="mb-20">Your booking details</h3>

                <div className="hotel-info">
                    {reserveHotelDetail && reserveHotelDetail.map((reserve, index) => (
                        <div className="row" key={index}>
                            <div className="col-sm-8">
                                <div className="hotel-info-wraps">
                                    <h5>{reserve.hotel_name}</h5>
                                    <p><span>{reserve.hotel_address}, </span><span>{reserve.hotel_city}, </span><span>{reserve.hotel_country}</span></p>
                                    <div className="includes">
                                        <span>Breakfast Included</span>
                                        <span>Free WiFi</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="img-wraps">
                                    <img src={`${baseURL}${reserve.hotel_image}`} alt="hotel" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="d-flex">

                    {
                        <div className="date-range-item">
                            <label className="mb-1">Check-in</label>
                            <div className="date-time bold mb-3">
                                <span className="checkin pr-3">{reserveDetail[0] && reserveDetail[0].check_in_date}</span>
                                <span>{reserveDetail[0] && reserveDetail[0].check_out_date}</span>
                            </div>
                            <div className="stay-nights">
                                <p>Total length of stay:</p>
                                {/* <p className="bold">{moment.duration(reserveDetail[0] && reserveDetail[0].check_out_date.diff(reserveDetail[0] && reserveDetail[0].check_out_date)).asDays()}</p> */}
                                <p className="bold">{Math.abs(
                                    moment(reserveDetail[0] && reserveDetail[0].check_in_date, 'YYYY-MM-DD')
                                        .startOf('day')
                                        .diff(moment(reserveDetail[0] && reserveDetail[0].check_out_date, 'YYYY-MM-DD').startOf('day'), 'days')
                                ) + 1
                                } {Math.abs(
                                    moment(reserveDetail[0] && reserveDetail[0].check_in_date, 'YYYY-MM-DD')
                                        .startOf('day')
                                        .diff(moment(reserveDetail[0] && reserveDetail[0].check_out_date, 'YYYY-MM-DD').startOf('day'), 'days')
                                ) + 1 > 1 ? 'days': 'day'}</p>
                            </div>

                        </div>
                    }
                </div>
            </div>
            <div className="details-card-payment mt-4">
                <h3 className="mb-20">Price summary</h3>
                <table className="w-100">
                    <thead>
                        <tr>
                            <th>{reserveDetail && reserveDetail[0] && reserveDetail[0].quantity} Room</th>
                            <td>NPR {reserveDetail && reserveDetail[0] && reserveDetail[0].sub_total}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>13 % VAT</th>
                            <td>{reserveDetail && reserveDetail[0] && reserveDetail[0].tax}</td>
                        </tr>
                    </tbody>

                </table>
                <table className="w-100 total-payment">
                    <thead>

                        <tr>
                            <th>Total Amount</th>
                            <td className="bold">NPR {reserveDetail && reserveDetail[0] && reserveDetail[0].sub_total}</td>
                        </tr>
                    </thead>
                </table>
            </div>

        </div>

    )
}
export default BookingDetail;