import * as hotelActionTypes from './../actions/hotelActions/hotelActionTypes'
const initState = {
    filterHotel: null,
    hotel: null,
    searchfilterHotel: null,
    hotelLoading: false,
    filterLoading: false,
    filterSearchLoading: false,
    searchData: {},
    hotelDataByid: null,
    hotelDataLoading: false,
    loadingInventory: false,
    inventory: [],
};

export const hotelReducer = (state = initState, action) => {
    switch (action.type) {
        case hotelActionTypes.LOADING_HOTEL:
            return {
                ...state,
                hotelLoading: true,
            }
        case hotelActionTypes.LOADING_HOTEL_SUCCESS:
            return {
                ...state,
                hotelLoading: false,
                hotel: action.hotel,
                searchData: action.searchHotelFilter
            }
        case hotelActionTypes.LOADING_HOTEL_ERROR:
            return {
                ...state,
                hotelLoading: false,
            }
        case hotelActionTypes.LOADING_HOTEL_BY_ID:
            return {
                ...state,
                hotelDataLoading: true,
            }
        case hotelActionTypes.LOADING_HOTEL_BY_ID_SUCCESS:
            return {
                ...state,
                hotelDataLoading: false,
                hotelDataByid: action.hotelIdData,
            }
        case hotelActionTypes.LOADING_HOTEL_BY_ID_ERROR:
            return {
                ...state,
                hotelDataLoading: false,
            }
        case hotelActionTypes.LOADING_INVENTORIES:
            return {
                ...state,
                loadingInventory: true,
            }
        case hotelActionTypes.LOADING_INVENTORIES_SUCCESS:
            return {
                ...state,
                loadingInventory: false,
                inventory: action.inventory.map(inv => ({...inv, checked: false})),
            }
        case hotelActionTypes.LOADING_INVENTORIES_ERROR:
            return {
                ...state,
                loadingInventory: false,
            }
        case hotelActionTypes.GET_FILTER_HOTEL:
            return {
                ...state,
                filterLoading: true,
            }
        case hotelActionTypes.GET_FILTER_HOTEL_SUCCESS:
            return {
                ...state,
                filterHotel: action.filterHotel,
                filterLoading: false,
            }
        case hotelActionTypes.GET_FILTER_HOTEL_ERROR:
            return {
                ...state,
                filterLoading: false,
            }

        case hotelActionTypes.SEARCH_FILTER_HOTEL:
            return {
                ...state,
                filterSearchLoading: true
            }
        case hotelActionTypes.SEARCH_FILTER_HOTEL_SUCCESS:
            return {
                ...state,
                hotel: action.searchFilterData,
                filterSearchLoading: false
            }
        case hotelActionTypes.SEARCH_FILTER_HOTEL_ERROR:
            return {
                ...state,
                filterSearchLoading: false
            }
        case "RESERVE_CHECKED":
            let newHotelList = [...state.inventory]
            const roomIndex = newHotelList.findIndex((inv) => inv.inventory_id === action.roomId)
            newHotelList[roomIndex].checked = action.value 
            return {
                ...state,
                inventory: newHotelList
            }
        default:
            return state

    }
}