import React from 'react';
import { Input } from 'antd';
import Background from '../../../../assets/images/bg/pattern4.jpg';

const { Search } = Input;

function Subscribe() {
    return (
        <section>
        <div className="search-destination-wrapper bg-cover" style={{ background: `url(${Background}) center center` }}>
            <div className="heading white-heading text-center pb-48">
                <h3 >Newsletter</h3>
                <h2>Subscribe for updates & promotions</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 d-block mx-auto">
                        <div className="search-wrap mt-7">
                        <Search
                                        placeholder="Email"
                                        allowClear
                                        enterButton="Subscribe"
                                        size="large"
                                        // onSearch={onSearch}
                                    />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}
export default  Subscribe;