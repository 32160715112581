import React, { useState, useEffect } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { checkBooking, registerGuestBooking } from '../../store/actions/bookingActions/bookingActionCreators';
import { createStorePayment, checkPointsRequest } from '../../store/actions/paymentActions/paymentActionCreator';
import BookingDetail from './components/BookingDetail';
import BookingSuccessModal from './components/BookingSuccess';
import KhaltiCheckout from "khalti-checkout-web";
import CheckPointDetail from './components/CheckPointDetail';
import { ToastContainer, toast } from 'react-toastify';


const Booking = (props) => {
    const bookings = useSelector(state => state.bookingReducer)
    const payments = useSelector(state => state.paymentReducer)
    const auth = useSelector(state => state.authReducer)
    const dispatch = useDispatch()
    const history = useHistory()

    const [classSearch, setclassSearch] = useState('search-outer-wrapper')
    const [margin, setMargin] = useState('mt-120')
    const [stepOne, setStepOne] = useState(true)
    const [stepTwo, setStepTwo] = useState(false)
    const [stepThree, setStepThree] = useState(false)
    const [otherGuestBooking, setOtherGuestBooking] = useState(false)
    const [guestFullName, setGuestFullName] = useState('')
    const [guestEmail, setGuestEmail] = useState('')
    const [email, setEmail] = useState('')
    const [fullName, setFullName] = useState('')
    const [travelwork, setTravelwork] = useState('no')
    const [phnNumber, setNumber] = useState('')
    const [country, setCountry] = useState('Nepal')
    const [errorMessage, setErrorMessage] = useState('')
    const [nameErrorMessage, setNameErrorMessage] = useState('')
    const [emailErrorMessage, setEmailErrorMessage] = useState('')
    const [paymentType, setPaymentType] = useState('in Hotel')
    const [showButon, setShowButon] = useState(true)

    const [bankShow, setBankShow] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState(false)
    const [checkPointStatus, setCheckPointStatus] = useState(false)
    const [checkPointData, setCheckPointData] = useState(false)
    const [bookingSuccess, setBookingSuccess] = useState(false)

    const handlerTravelWork = (e) => {
        setTravelwork(e.target.value);
    }

    const fullNameHandler = (e) => {
        setFullName(e.target.value);
    }

    const emailHandler = (e) => {
        setEmail(e.target.value);
    }
    const guestNameHandler = (e) => {
        setGuestFullName(e.target.value);
    }

    const guestEmailHandler = (e) => {
        setGuestEmail(e.target.value);
    }

    const handleCountrySelection = (e) => {
        setCountry(e.target.value);
    }

    const handlePhoneNum = (e) => {
        setNumber(e.target.value)
    }


    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY === 0) {
                setclassSearch('')
                setMargin('mt-120')
            } else {
                setclassSearch('sticky-search')
                setMargin('mt-50')
            }
        });
    }, [classSearch]);

    const bookingConfirm = () => {
        const moduleId = {
            module_id: bookings.moduleId,
        };
        dispatch(checkBooking(moduleId, (res, err) => {
            if (err) {
                console.log("err");
                history.goBack()
            }
        }));
    }

    const handlDetaillNext = () => {

        if (fullName === '' && travelwork === '' && email === '') {
            setErrorMessage('Complete Your detail first')
        } else if (fullName === '') {
            setNameErrorMessage('Full Name is required')
        } else if (email === '') {
            setEmailErrorMessage('Email is required')
        } else {
            setStepOne(false);
            setStepTwo(true);
        }

    }

    const handlAddressNext = () => {

        if (phnNumber === '') {
            setErrorMessage('Phone nUmber is required')
        }
        const data = {
            'name': guestFullName,
            'email': guestEmail,
            'contact': phnNumber,
            'country': country,
            'booking_id': bookings.bookingId,
        }

        if (otherGuestBooking) {
            dispatch(registerGuestBooking(data, (res, err) => {
                if (res) {
                    setStepOne(false);
                    setStepTwo(false);
                    setStepThree(true)
                }
            }))
        } else {
            setStepOne(false);
            setStepTwo(false);
            setStepThree(true)
        }

    }

    const guestSwitch = (e) => {
        setOtherGuestBooking(e.target.checked)
    }

    const checkAuthentication = () => {
        setEmail(auth && auth.user && auth.user.email)
    }

    const electronicPayment = (method, paymentStatus) => {
        let grandTotalPrice = bookings.bookingData && bookings.bookingData.modules && bookings.bookingData.modules.map((book) => book.sub_total);
        let discount = bookings.bookingData && bookings.bookingData.modules && bookings.bookingData.modules.map((book) => book.discount);
        let tax = bookings.bookingData && bookings.bookingData.modules && bookings.bookingData.modules.map((book) => book.tax);
        const data = {
            method: method,
            pType: paymentType,
            pStatus: paymentStatus,
            totalPrice: grandTotalPrice,
            discount: discount,
            tax: tax,
            bookingId: bookings.bookingId,
        }
        dispatch(createStorePayment(data, (res, err) => {
            if (res) {
                setBookingSuccess(true)
            }
        }))


    }

    const handleCheckPoints = () => {
        dispatch(checkPointsRequest((res, err) => {
            if (res) {
                setCheckPointData(payments.points);
                setCheckPointStatus(true);
            }
            if (err) {
                console.log("err", err);
            }
        }))
    }

    const hideCheckpoint = () => {
        setCheckPointStatus(false)
    }

    const handleCompleteBooking = () => {
        let method = ''
        let paymentStatus = ''
        if (!paymentMethod) {
            method = "offline";
             paymentStatus = "incomplete"
            electronicPayment(method, paymentStatus)
        } else {
            handleCheckPoints();
        }
    }

    const handleKhalti = (e) => {
        if (e.target.checked) {
            setPaymentType("Khalti")
            setShowButon(true)
        }
    }

    const handleEsewa = (e) => {
        if (e.target.checked) {
            setPaymentType("Esewa")
            setShowButon(false)

            toast.error("Currently Not Available")
        }
    }

    const handleBank = (e) => {
        if (e.target.checked) {
            setPaymentType("Bank")
            setBankShow(false)
            setShowButon(false)

            toast.error("Currently Not Available")
        }
    }

    const handleCheckoutPayment = () => {
        let payPrice = bookings.bookingData && bookings.bookingData.modules && bookings.bookingData.modules[0].sub_total * 100;
        console.log("payPrice", bookings.bookingData);
        console.log("payPrice", payPrice);
        let config = {
            "publicKey": "test_public_key_30d7fea2b1284f3890e34a219587b3a3",
            "productIdentity": bookings.bookingId,
            "productName": "Drogon",
            "productUrl": "http://gameofthrones.com/buy/Dragons",
            "eventHandler": {
                onSuccess(payload) {
                    checkPointStatus(false)
                    let method = 'online';
                    let paymentStatus = 'complete'
                    electronicPayment(method, paymentStatus)
                    // console.log(payload);
                },
                onError(error) {
                    toast.error(error)
                },
                onClose() {
                    console.log('widget is closing');
                }
            },
            "paymentPreference": ["KHALTI", "EBANKING", "MOBILE_BANKING", "CONNECT_IPS", "SCT"],
        };
        let checkout = new KhaltiCheckout(config);
        checkout.show({ amount: payPrice });
    }

    const handlePaymentMethod = (e) => {
        setPaymentMethod(e.target.checked)
        if(e.target.checked){
            setShowButon(false)
        }else{
            setShowButon(true)
        }
    }

    useEffect(() => {
        if (bookings.moduleId.length > 0) {
            bookingConfirm()
        }
        checkAuthentication();
    }, [bookings.moduleId])

    if (!localStorage.getItem("accessToken")) {
        return <Redirect to="/login" />
    }
    return (
        <div className="content-wrapper" >
            <div className={`search-outer-wrapper ${classSearch}`}>
                <div className="container">
                    <div className="booking-process">
                        <ul className="progress-indicator">
                            <li className={(stepOne || stepTwo || stepThree) ? 'completed' : ''}> <span className="bubble"></span> Step 1. </li>
                            <li className={(!stepOne || stepTwo || stepThree) ? 'completed' : ''}> <span className="bubble"></span> Step 2. </li>
                            <li className={(stepOne && stepTwo || stepThree) ? 'completed' : ''}> <span className="bubble"></span> Step 3. </li>
                        </ul>
                    </div>

                </div>
            </div>

            <div className={`container ${margin}`}>
                <div className="search-result-wrapper">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="reserve-wrapper">
                                {stepOne &&
                                    <div className="reserve-wrapper-inner">
                                        <div className="d-flex headings">
                                            <h5>Enter your details</h5>
                                            <div className="ml-auto">
                                                <Form>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        label="Booking is for someone else"
                                                        onChange={guestSwitch}
                                                    />
                                                </Form>
                                            </div>
                                        </div>
                                        <div className="form-outer">
                                            <Form>
                                                {errorMessage && <div className="text-danger">{errorMessage} </div>}

                                                <Form.Row>
                                                    <Form.Group as={Col} md="6" controlId="formBasicEmail">
                                                        <Form.Label>Are you Travelling For Work</Form.Label>
                                                        <Form.Control as="select" onChange={handlerTravelWork}>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Form.Row>

                                                <Form.Row>
                                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                        <Form.Label>Full Name</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Full Name"
                                                            value={fullName}
                                                            onChange={fullNameHandler}
                                                        />
                                                        {nameErrorMessage && <div className="text-danger">{nameErrorMessage} </div>}

                                                    </Form.Group>

                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} md="6" controlId="formBasicEmail">
                                                        <Form.Label>Email address</Form.Label>
                                                        <Form.Control type="email" placeholder="Enter email"
                                                            value={email}
                                                            onChange={emailHandler} />
                                                        {emailErrorMessage && <div className="text-danger">{emailErrorMessage} </div>}
                                                    </Form.Group>
                                                </Form.Row>
                                                {!otherGuestBooking &&
                                                    <Form.Group controlId="formBasicCheckbox" className="text-right">
                                                        <Button variant="primary" className="ml-auto w-auto px-5 py-2" onClick={handlDetaillNext}>Next</Button>

                                                    </Form.Group>}
                                            </Form>
                                        </div>

                                    </div>
                                }
                                {(stepOne && otherGuestBooking) &&
                                    <div className="reserve-wrapper-inner">
                                        <div className="d-flex headings">
                                            {/* <div className="back mr-2">
                <i className="fas fa-arrow-left"></i>
            </div> */}
                                            <h5>Enter Guest Details</h5>
                                        </div>
                                        <div className="form-outer">
                                            <Form>
                                                {errorMessage && <div className="text-danger">{errorMessage} </div>}
                                                <Form.Row>
                                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                        <Form.Label>Full Name</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Full Name"
                                                            value={guestFullName}
                                                            onChange={guestNameHandler}
                                                        />
                                                        {nameErrorMessage && <div className="text-danger">{nameErrorMessage} </div>}

                                                    </Form.Group>

                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} md="6" controlId="formBasicEmail">
                                                        <Form.Label>Email address</Form.Label>
                                                        <Form.Control type="email" placeholder="Enter email"
                                                            value={guestEmail}
                                                            onChange={guestEmailHandler} />
                                                        {emailErrorMessage && <div className="text-danger">{emailErrorMessage} </div>}
                                                    </Form.Group>
                                                </Form.Row>


                                                <Form.Group controlId="formBasicCheckbox" className="text-right">
                                                    <Button variant="primary" className="ml-auto w-auto px-5 py-2" onClick={handlDetaillNext}>Next</Button>

                                                </Form.Group>
                                            </Form>
                                        </div>


                                    </div>

                                }
                                {stepTwo &&
                                    <div className="reserve-wrapper-inner">
                                        <div className="d-flex headings">
                                            <h5>Enter Your Address</h5>
                                        </div>
                                        <div className="form-outer">
                                            <Form>
                                                <Form.Row>
                                                    <Form.Group as={Col} md="6" controlId="formGridState">
                                                        <Form.Label>Country/region</Form.Label>
                                                        <Form.Control as="select" defaultValue="Choose..." onChange={handleCountrySelection}>
                                                            <option>Choose...</option>
                                                            <option value="nepal">Nepal</option>
                                                            <option value="India">India</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} md="6" controlId="formBasicEmail">
                                                        <Form.Label>Phone phnNumber</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Phone Number" onChange={handlePhoneNum} />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Group controlId="formBasicCheckbox" className="text-right">
                                                    <Button variant="primary" className="ml-auto w-auto px-5 py-2" onClick={handlAddressNext}>Next</Button>

                                                </Form.Group>
                                            </Form>
                                        </div>

                                    </div>
                                }

                                {stepThree &&
                                    <div className="reserve-wrapper-inner">
                                        <div className="d-flex headings">
                                            <h5>Book from Online Payment</h5>
                                            <div className="ml-auto">
                                                <Form>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        label={paymentMethod ? 'online' : 'At Hotel'}
                                                        onChange={handlePaymentMethod}

                                                    />
                                                </Form>
                                            </div>
                                        </div>
                                        <div className="form-outer">
                                            <Form>
                                                {paymentMethod &&
                                                    <div className="form-wrapper">
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Label>Are you travelling for work?</Form.Label>

                                                            <Form.Check
                                                                type="radio"
                                                                label="esewa"
                                                                name="payment"
                                                                id="esewa"
                                                                onChange={handleEsewa}
                                                            />
                                                            <Form.Check
                                                                type="radio"
                                                                label="Khalti"
                                                                name="payment"
                                                                id="khalti"
                                                                onChange={handleKhalti}
                                                            />
                                                            <Form.Group controlId="formBasicEmail">
                                                                <Form.Check
                                                                    type="radio"
                                                                    label="Bank"
                                                                    name="payment"
                                                                    id="bank"
                                                                    onChange={handleBank}
                                                                />
                                                            </Form.Group>

                                                        </Form.Group>

                                                        {bankShow && <Form.Row>
                                                            <Form.Group as={Col} md="6" controlId="formBasicEmail">
                                                                <Form.Label>Account Number</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter Account Number" />
                                                            </Form.Group>
                                                        </Form.Row>}
                                                    </div>
                                                }
                                                <Form.Group controlId="formBasicCheckbox" className="text-right">
                                                    {
                                                    
                                                    showButon ?
                                                    <Button variant="primary" className="ml-auto w-auto px-5 py-2" onClick={handleCompleteBooking}>Complete Booking</Button> 
                                                    : 
                                                    <Button variant="primary" className="ml-auto w-auto px-5 py-2" disabled >Complete Booking</Button>
                                                    }

                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-5">
                            <BookingDetail />
                        </div>

                    </div>
                </div>
            </div>

            <BookingSuccessModal show={bookingSuccess} />
            <CheckPointDetail show={checkPointStatus} data={checkPointData} hide={hideCheckpoint} save={handleCheckoutPayment} />
            <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                />
        </div>
    )
}

export default Booking;