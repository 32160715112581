/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
// import Slider from "react-slick";
import TourPackageCard from '../../../../component/Card/TourPackageCard';

class TourPackage extends Component {
    render() {
       
        return (
            // Section Tour Packages 
            <section className="ptb-48 bg-white">
                <div className="container">
                    <div className="tour-package-wrapper">
                        <div className="heading text-center pb-48">
                            <h3 >Top</h3>
                            <h2>Travel Packages</h2>
                            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
                        </div>
                        <div className="tour-package-layer">
                            <div className="row">
                                
                                    <TourPackageCard />
                                    <TourPackageCard />
                                    <TourPackageCard />
                               
                            </div>
                            
                        </div>
                    </div>
                </div>

            </section>
            // End of Section Tour Packages 
        )
    }
}

export default TourPackage;
