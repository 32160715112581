import * as locationActionTypes from './locationActionType';
import axios from '../../../helpers/axios';


export const getLocations = (keyword, cb) =>{
    return async(dispatch) =>{
        const formData = new FormData();
        formData.append('keyword', keyword);
        try{
            dispatch({
                type: locationActionTypes.LOCATION_LOADING
            })
            const response = await axios.post(`/search_by_hotel_or_city_or_landmark/`, formData);
            if(response.status === 200){
                dispatch({
                    type:locationActionTypes.LOCATION_LOADING_SUCCESS,
                    locations: response.data,
                    count: response.total_data_count,
                 
                })
                cb(response, null);
            }else{
                dispatch({
                    type:locationActionTypes.LOCATION_LOADING_ERROR
                })
                cb(false, true);
            }
        }catch(err){
            dispatch({
                type:locationActionTypes.LOCATION_LOADING_SUCCESS
            })
            cb(false, err);
        }
    }
}