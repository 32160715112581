/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faStarHalfAlt, faStar, faBus, faHeart, faBolt } from '@fortawesome/free-solid-svg-icons';


class CardLayout extends Component {
  render() {

    return (
      <div className="card-outer">
        <div className="card">
          <a href="#" className="card-link-overlay"> <span className="hidden">link</span></a>
          <div className="card-ribbon ribbon-top-left">
            <span className="bg-warning text-center">
              <FontAwesomeIcon icon={faBolt} />
            </span>

          </div>
          <div className="card-icons">
            <a href="#" className="item-circle bg-primary d-block text-center">
              <FontAwesomeIcon icon={faBus} />
            </a>
            <a href="#" className="item-circle bg-secondary d-block text-center">
              <FontAwesomeIcon icon={faHeart} />
            </a>
          </div>
          <div className="card-img-top">
            <img src="https://www.spruko.com/demo/gowell/gowell/assets/images/categories/02.png" className="img-fluid" alt="package-img" />
            <div className="pricing bg-secondary">
              <h4>$460</h4>
            </div>
          </div>
          <div className="card-body">
            <div className="card-body-heading">
              <a href="#" className="text-dark mb-3 d-block">Japan Beautiful Places</a>
            </div>
            <div className="card-body-text">
              <p> <span className="days">12 Days,</span> <span className="time">12 Nights</span> Travel Trip</p>
              <p className="desc mt-1">Lorem ipsum dolor sit amet, quis int nostrum exercitationem </p>
            </div>
          </div>
          <div className="card-footer d-flex">
            <ul className="place listing mb-1">
              <li>
                <a href="#"><FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-muted" />London - 3 Places</a>

              </li>
            </ul>
            <div className="rating d-flex ml-auto">
              <div className="rating-inner">
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStarHalfAlt} />
              </div>
              <p className="count-rating ml-2">(24)</p>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default CardLayout;
