/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import Slider from "react-slick";
import HolidayCard from '../../../../component/Card/HolidayCard';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        />
    );
}

class Holiday extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 8000,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,

                  }
                },
              ]
        };
        return (
            // Section  Holidays 
            <section className="ptb-48">
                <div className="container">
                    <div className="holiday-wrapper">
                        <div className="heading text-center pb-48">
                            <h3 >Top Most</h3>
                            <h2>Best Holiday Trips</h2>
                            <p>Most Beautiful Places in the world</p>

                        </div>
                        <Slider {...settings}>
                            <HolidayCard />
                            <HolidayCard />
                            <HolidayCard />
                            <HolidayCard />
                            
                        </Slider>
                    </div>
                </div>

            </section>
            // End of Section  Holiday 
        )
    }
}

export default Holiday;
