import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL_VERSION

let headers = {}

if(localStorage.token){
    headers.Authorization = `Bearer ${localStorage.token}`;
}
const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: { 'Content-Type': 'application/json' },
   
})

export default axiosInstance;

