import React, { useState, useEffect } from 'react';

import { Link, useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Breadcrumb,  Badge } from 'react-bootstrap'
import Star from '../../assets/images/icon/star.svg';
import Bed from '../../assets/images/icon/beds.svg';
import PlaceholderImg from '../../assets/images/placeholder.png';

import SearchPanel from '../../component/SearchPanel/SearchPanel'
import { getHotels } from '../../store/actions/hotelActions/hotelActionCreators';
import FilterHotels from '../../component/filter/filterHotels';
const baseURL = process.env.REACT_APP_BASE_URL


const SearchResult = (props) => {
    const hotel = useSelector(state => state.hotelReducer)

    const [classSearch, setclassSearch] = useState('search-outer-wrapper')
    const [margin, setMargin] = useState('mt-120');

    const dispatch = useDispatch();
    const history = useHistory();
    const [searchDataList, setSearchDataList] = useState(JSON.parse(localStorage.getItem("searchItems")))

    const [resultData, setResultData] = useState([]);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY === 0) {
                setclassSearch('')
                setMargin('mt-120')
            } else {
                setclassSearch('sticky-search')
                setMargin('mt-50')
            }
        });
    }, [classSearch]);

    const fetchSearchData = () =>{
        const searchData = JSON.parse(localStorage.getItem("searchItems"));
        setSearchDataList(searchData);
     }
 
    const fetchHotelResult = () => {
        dispatch(getHotels(searchDataList, (res, err) => {
            if (err) {
                history.push('/')
            }
        }
        ))
    }

   
    useEffect(() => {
        fetchSearchData();
        fetchHotelResult();
    }, [])

    useEffect(() =>{
        setResultData(hotel.hotel)
    }, [hotel.hotel])

    return (
        <div className="content-wrapper" >
            <div className={`search-outer-wrapper ${classSearch}`}>
                <div className="container">
                    <SearchPanel searchList={searchDataList} />
                </div>
            </div>
            <div className={`container ${margin}`}>
                <div className="breadcrumbs-wrap">
                    <Breadcrumb>
                            <li className="breadcrumb-item"><Link to='/home'>Home</Link></li>
                            <li className="breadcrumb-item active">Search Result</li>
                    </Breadcrumb>
                </div>
                <div className="search-result-wrapper mb-5">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="filter-sidebar">
                                <FilterHotels />
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="result-wrapper">
                                <h3 className="mb-20">{resultData && resultData[0]?.hotel_city}:  <span className="pl-2">{resultData && resultData.length} Result Found</span> </h3>
                                <div className="result-card-wrapper">
                                    {
                                        resultData && resultData.map((result, index) =>
                                            <div className="result-card mb-15" key={index}>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="img-wrap">
                                                            {result.hotel_image ? <img src={`${baseURL}${result.hotel_image}`} alt="hotelImg" className="img-fluid" /> :<img src={PlaceholderImg} alt="hotelImg" className="img-fluid" /> }
                                                            
                                                            {/* <img src={PlaceholderImg} alt="hotelImg" className="img-fluid" /> */}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="search-hotel-detail-wrap">
                                                            <Link to={`hotel/${result.hotel_id}`}
                                                            ><h4 className="mb-1">{result.hotel_name}</h4>
                                                            </Link>

                                                            <small>{result.hotel_address} {result.hotel_city}, {result.hotel_country}</small>
                                                            <div className="rating-badge d-flex align-items-center">
                                                                {result.hotel_star_rating ?
                                                                    <Badge variant="success">
                                                                        <img src={Star} className="img-fluid" alt="star" />
                                                                        <span className="pl-2">4.5</span>
                                                                    </Badge> :
                                                                    <Badge variant="success">
                                                                        <img src={Star} className="img-fluid" alt="star" />
                                                                        <span className="pl-2">2.5</span>
                                                                    </Badge>
                                                                }
                                                                {/* <span className="count">450 Reviews</span> */}
                                                            </div>
                                                            <div className="room-type d-flex mb-2">
                                                                {
                                                                    result && result.hotel_inventories && result.hotel_inventories.map((inv, index) =>
                                                                        <p className="d-flex align-items-center" key={index}><span className="pr-2"><img src={Bed} className="img-fluid" alt="beds" /></span>{inv.name}</p>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className="items-available d-flex mb-15">
                                                                <ul className="text-success">
                                                                    <li>Breakfast Included</li>
                                                                    <li>Free Cancellation</li>
                                                                </ul>
                                                            </div>
                                                            <p className="info">You can cancel later, so lock in this great price today.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 d-flex">
                                                        <div className="search-result-pricing">
                                                            <h2 className="mb-1">NPR {result.minimum_price}</h2>
                                                            <small>per room per night</small>
                                                            <Link to={`hotel/${result.hotel_id}`}
                                                            ><button className="btn btn-primary mt-10">Book Now</button>
                                                            </Link>
                                                            <p className="mt-10">Includes taxes and charges</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        )}
                                </div>
                          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchResult;