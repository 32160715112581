
import React from 'react';
import Travel from '../../../../assets/images/travel.png';

function FeaturedVideo() {
    return (
        <section className="ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 ">
                        <div className="featured-video">
                            <div className="heading-2">
                                <h3>Featured Video</h3>
                                <p>Travel Like A free Bird</p>
                            </div>
                            <div className="contents mt-3">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                <button className="btn-none mt-3 d-flex align-items-center">
                                    More Video 
                                <span className="pl-3"><i className="fas fa-arrow-right"></i></span>
                                </button>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="featured-wrap">
                            <img src={Travel} alt="tavel" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default FeaturedVideo;