/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Background from '../../assets/images/tourplace.png';

class TourPlacesCard extends Component {
    render() {
        return (
            <div className="card-outer-layer">
                <div className="card tour-place-card" style={{ background: `url(${Background})` }}>
                    <div className="card-body text-white">
                        <h2 className="mtb-10 text-white">Paris Tour</h2>
                        <p className="mt-4 mb-4">We denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment</p>
                        <a className="btn btn-secondary" href="#">View Details</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default TourPlacesCard;
