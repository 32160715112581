import React from 'react';
import { Modal, Button } from 'react-bootstrap'
// import { Link } from 'react-router-dom';

const CheckPointDetail = (props) => {
  return (
    <Modal show={props.show} centered className="Checking-points p-4" onHide={props.hide}>
      <Modal.Header closeButton>
          <Modal.Title>Do you want to use points?</Modal.Title>
        </Modal.Header>
      <Modal.Body className="p-3">
          <h6 className="mb-2">Your Points Details</h6>
          <p>Total Points: {props.data.available_points}</p>
      </Modal.Body>
      <Modal.Footer>
          <Button variant="secondary" onClick={props.hide}>
            No
          </Button>
          <Button variant="primary" onClick={props.save}>
            Yes
          </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default CheckPointDetail;
