import * as hotelActionTypes from './hotelActionTypes';
import axios from '../../../helpers/axios';


export const buildHotelRequest = (searchKeywordList) => {
    const formData = new FormData();
    formData.append('location', searchKeywordList.location)
    formData.append('number_of_adult', searchKeywordList.adults)
    formData.append('number_of_child', searchKeywordList.childrens)
    formData.append('number_of_infant', searchKeywordList.infants)
    formData.append('check_in_date', searchKeywordList.checkin)
    formData.append('check_out_date', searchKeywordList.checkout)
    formData.append('required_room', searchKeywordList.room)
    return formData;
};

export const buildInventoryRequest = (searchKeywordList) => {
    const formData = new FormData();
    formData.append('location', searchKeywordList.searchingList.location)
    formData.append('number_of_adult', searchKeywordList.searchingList.adults)
    formData.append('number_of_child', searchKeywordList.searchingList.childrens)
    formData.append('number_of_infant', searchKeywordList.searchingList.infants)
    formData.append('check_in_date', searchKeywordList.searchingList.checkin)
    formData.append('check_out_date', searchKeywordList.searchingList.checkout)
    formData.append('required_room', searchKeywordList.searchingList.room)
    formData.append('hotel_id', searchKeywordList.hotel_id)
    return formData;
};

export const getHotels = (data, cb) => {
    const responseData = buildHotelRequest(data);
    return async (dispatch) => {
        try {
            dispatch({
                type: hotelActionTypes.LOADING_HOTEL
            });
            const response = await axios.post(`/hotel_search_by_parameters/`, responseData);
            if (response.status === 200) {
                dispatch({
                    type: hotelActionTypes.LOADING_HOTEL_SUCCESS,
                    hotel: response.data.hotel.data,
                    total: response.data.hotel.total_data_count,
                    searchHotelFilter: data,
                })
                window.localStorage.setItem("searchItems", JSON.stringify(data));
                cb(response, null)
            } else {
                dispatch({
                    type: hotelActionTypes.LOADING_HOTEL_ERROR
                })
                cb(false, true)
            }
        } catch (err) {
            dispatch({
                type: hotelActionTypes.LOADING_HOTEL_ERROR
            })
            cb(false, err)
        }
    }
}

export const fetchFilter = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: hotelActionTypes.GET_FILTER_HOTEL
            });
            const response = await axios.post(`/all_hotel_filters/`, data);
            if (response.status === 200) {
                dispatch({
                    type: hotelActionTypes.GET_FILTER_HOTEL_SUCCESS,
                    filterHotel: response.data
                });
            } else {
                dispatch({
                    type: hotelActionTypes.GET_FILTER_HOTEL_ERROR
                });
            }
        } catch (err) {
            dispatch({
                type: hotelActionTypes.GET_FILTER_HOTEL_ERROR
            });
        }
    }
}


export const searchByFilter = (data, cb) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: hotelActionTypes.SEARCH_FILTER_HOTEL
            })
            const response = await axios.post(`/filter_inventories_by_parameters/`, data);
            if (response.status === 200) {
                dispatch({
                    type: hotelActionTypes.SEARCH_FILTER_HOTEL_SUCCESS,
                    searchFilterData: response.data.hotel.data,
                })
                cb(true, null);
            } else {
                dispatch({
                    type: hotelActionTypes.SEARCH_FILTER_HOTEL_ERROR
                })
                cb(false, null);
            }
        } catch (err) {
            dispatch({
                type: hotelActionTypes.SEARCH_FILTER_HOTEL_ERROR
            })
            cb(false, err);
        }
    }
}


export const getHotelById = (id, cb) => {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            formData.append('hotel_id', id)
            dispatch({
                type: hotelActionTypes.LOADING_HOTEL_BY_ID
            });
            const response = await axios.post(`/hotel_search_by_id/`, formData);
            if (response.status === 200) {
                dispatch({
                    type: hotelActionTypes.LOADING_HOTEL_BY_ID_SUCCESS,
                    hotelIdData: response.data.hotel.data,
                    total: response.data.hotel.total_data_count,
                })
                cb(response, null)
            } else {
                dispatch({
                    type: hotelActionTypes.LOADING_HOTEL_BY_ID_ERROR
                })
                cb(false, true)
            }
        } catch (err) {
            dispatch({
                type: hotelActionTypes.LOADING_HOTEL_BY_ID_ERROR
            })
            cb(false, err)
        }
    }
}

export const getInventoryByHotelId = (data, cb) =>{
    const responseData = buildInventoryRequest(data);
    return async (dispatch) => {
        try {
            dispatch({
                type: hotelActionTypes.LOADING_INVENTORIES
            });
            const response = await axios.post(`/inventory_search_by_hotel/`, responseData);
            if (response.status === 200) {
                dispatch({
                    type: hotelActionTypes.LOADING_INVENTORIES_SUCCESS,
                    inventory: response.data.inventories.data,
                })
                cb(response, null)
            } else {
                dispatch({
                    type: hotelActionTypes.LOADING_INVENTORIES_ERROR
                })
                cb(false, true)
            }
        } catch (err) {
            dispatch({
                type: hotelActionTypes.LOADING_INVENTORIES_ERROR
            })
            cb(false, err)
        }
    }
}

