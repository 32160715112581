/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Place from '../../assets/images/london.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEye, faStarHalfAlt, faStar } from '@fortawesome/free-solid-svg-icons';


class LocationCard extends Component {
    render() {
        return (
            <div className="card-outer-layer">
            <div className="card text-center mb-0">
                <a href="#" className="card-link-overlay"> <span className="hidden">link</span></a>
                <div className="card-img">
                    <img src={Place} alt="travel" className="img-fluid" />
                </div>
                <div className="card-img-body text-white text-left">
                    <div className="rating-inner mb-3">
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStarHalfAlt} />
                    </div>
                    <h4 className="mb-2">Germany</h4>
                    <small className="text-white"> <FontAwesomeIcon icon={faMapMarkerAlt} /> 8 Cities<FontAwesomeIcon icon={faEye} className="ml-3" /> 140+ Tour Places </small>
                </div>
            </div>
        </div>
        )
    }
}

export default LocationCard;
