/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
// import Slider from "react-slick";
import FeatureCard from '../../../../component/Card/FeatureCard';

class Feature extends Component {
    render() {
       
        return (
            // Section Feature 
            <section className="ptb-48">
                <div className="container">
                    <div className="feature-wrapper">
                        <div className="heading text-center pb-48">
                            <h3 >Our Tour</h3>
                            <h2>Features</h2>
                            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
                        </div>
                        <div className="feature-layer">
                            <FeatureCard />
                        </div>
                    </div>
                </div>

            </section>
            // End of Feature
        )
    }
}

export default Feature;
