import * as bookingActionTypes from './bookingActionType';
import axios from '../../../helpers/axios';


export const createBooking = (data, cb) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: bookingActionTypes.CHECK_BOOKING
            })
            const response = await axios.post(`/module_booking/`, data, {
                headers: {
                    Authorization: `Token ${getState().authReducer.token}`,
                },
            });
            if (response.status === 200) {
                dispatch({
                    type: bookingActionTypes.LOADING_BOOKING_SUCCESS,
                    module_id: response.data.module_id,
                    userBookingDetails: data,
                })
                cb(true, null)
            }

            else {
                dispatch({
                    type: bookingActionTypes.LOADING_BOOKING_ERROR
                })
                cb(false, true)

            }
        } catch (err) {
            dispatch({
                type: bookingActionTypes.LOADING_BOOKING_ERROR
            })
            cb(false, err)

        }
    }
}

export const checkBooking = (id, cb) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: bookingActionTypes.CHECK_BOOKING
            })
            const response = await axios.post(`/check_booking_again/`, id, {
                headers: {
                    Authorization: `Token ${getState().authReducer.token}`,
                },
            });

            if (response.status === 200) {
                dispatch({
                    type: bookingActionTypes.CHECK_BOOKING_SUCCESS,
                    bookingId: response.data.booking_id,
                })
                cb(true, null);
            }
            else {
                dispatch({
                    type: bookingActionTypes.CHECK_BOOKING_ERROR
                })
                cb(false, true);

            }
        } catch (err) {
            dispatch({
                type: bookingActionTypes.CHECK_BOOKING_ERROR
            })
            cb(false, err);

        }
    }
}

export const buildRegisterUser = (registerInfo) => {
    const formData = new FormData();
    formData.append('name', registerInfo.name)
    formData.append('email', registerInfo.email)
    formData.append('contact', registerInfo.contact)
    formData.append('country', registerInfo.country)
    formData.append('booking_id', registerInfo.booking_id)
    return formData;
};

export const registerGuestBooking = (data, cb) => {
    let registerUser = buildRegisterUser(data)
    return async (dispatch, getState) => {

        try {
            dispatch({
                type: bookingActionTypes.REGISTER_GUEST_BOOKING
            })
            const response = await axios.post(`/register_guest/`, registerUser, {
                headers: {
                    Authorization: `Token ${getState().authReducer.token}`,
                },
            });
            if (response.status === 200) {
                dispatch({
                    type: bookingActionTypes.REGISTER_GUEST_BOOKING_SUCCESS,
                    userInfo: response.data.info,
                   
                })
                cb(true, false);
            } else {
                dispatch({
                    type: bookingActionTypes.REGISTER_GUEST_BOOKING_ERROR
                })
                cb(false, true);

            }
        }
        catch (err) {
            dispatch({
                type: bookingActionTypes.REGISTER_GUEST_BOOKING_ERROR
            })
            cb(false, err);

        }
    }
}