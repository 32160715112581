/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import SearchPanel from '../../../../component/SearchPanel/SearchPanel';

import BgVideo from '../../../../assets/video/bg.mp4';
import Tour from '../../../../assets/images/icon/tour.svg';
import Hotel from '../../../../assets/images/icon/hotel.svg';
import Flights from '../../../../assets/images/icon/flights.svg';
import Rental from '../../../../assets/images/icon/rental.svg';

// import { useHistory } from 'react-router-dom'

const  Banner = () => {
  
    // const history = useHistory();

    // useEffect(() =>{
    //     // SearchAPI(history)(bookingDispatch)
    // }, []);

    return (
            <section className="banner-layer ">
                <div className="overlay-banner"></div>
                <div className="row m-0">
                    <div className="col-sm-12 p-0">
                        <div className="video-wrapper">
                            <div className="video-wrap">
                                <video autoPlay loop muted id="bannerVideo">
                                    <source src={BgVideo} type="video/mp4" />
                                </video>
                            </div>
                            <div className="banner-tabs">
                                <div className="banner-tabs-heading mb-30">
                                    <h2>Find best deals on</h2>
                                </div>
                                <Tabs
                                    id="controlled-tab-destination"
                                    
                                >
                                   
                                    <Tab eventKey="hotel" title={<div> <span className="text">Hotel</span> <span className="icon"> <img src={Hotel} className="img-fluid" alt="hotel" /></span></div>}>
                                     <SearchPanel />
                                    </Tab>
                                    <Tab eventKey="tour" title={<div> <span className="text">Tour</span> <span className="icon"> <img src={Tour} className="img-fluid" alt="tour" /></span></div>}>
                                        {/* <SearchPanel /> */}
                                    </Tab>
                                    <Tab eventKey="flights" title={<div> <span className="text">Flights</span> <span className="icon"> <img src={Flights} className="img-fluid" alt="flights" /></span></div>}>
                                        {/* <SearchPanel /> */}
                                    </Tab>
                                    <Tab eventKey="rental" title={<div> <span className="text">Rental</span> <span className="icon"> <img src={Rental} className="img-fluid" alt="rental" /></span></div>}>
                                        {/* <SearchPanel /> */}
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        )
}

export default Banner;
