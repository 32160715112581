import React from 'react'
import SearchPanel from '../../component/SearchPanel/SearchPanel'

 function Detail() {
    return (
        <div className="content-wrapper" >
        <div className="sticky-search">
            <div className="container">
            <SearchPanel />
            </div>
        </div>
        <div className="container mt-100">
           <div className="details-page-wrapper">
               
           </div>
          
        </div>
    </div>
    )
}


export default Detail;