/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import BlogCard from '../../../../component/Card/BlogCard';

class Blog extends Component {
    render() {
        return (
            // Section Blog 
            <section className="ptb-48 bg-white">
                <div className="container">
                    <div className="blog-wrapper">
                        <div className="heading text-center pb-48">
                            <h3 >New</h3>
                            <h2>Blog News</h2>
                            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>

                        </div>
                        <div className="blog-list-wrapper">
                            <div className="row">
                            <BlogCard />
                            <BlogCard />
                            <BlogCard />
                            <BlogCard />
                            <BlogCard />
                            <BlogCard />
                            </div>
                        </div>  
                    </div>
                </div>
            </section>
            // Section Blog  Ends
        )
    }
}

export default Blog;
