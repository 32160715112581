/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import { Switch, Route } from 'react-router-dom';
import MasterRoutes from './Routes/routes';
import { checkAuth } from './store/actions/authActions/authActionCreators'
import {useDispatch } from 'react-redux';
import 'leaflet/dist/leaflet.css'
import 'react-toastify/dist/ReactToastify.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function App() {

  const dispatch = useDispatch();

    useEffect(() => {
      dispatch(checkAuth());
    }, [dispatch]);
    
  return (
    <Switch>
    <Route path="/" component={MasterRoutes} />
  </Switch>
   
  );
}

export default App;
