import * as bookingActionTypes from '../actions/bookingActions/bookingActionType';

const initState = {
    bookingLoading: false,
    checkingLoading: false,
    guestLoading: false,
    moduleId: [],
    bookingId: null,
    bookingData: {},
    registerGuestInfo: null,

}

export const bookingReducer = (state = initState, action) => {
    switch (action.type) {
        case bookingActionTypes.LOADING_BOOKING:
            return {
                ...state,
                bookingLoading: true
            }
        case bookingActionTypes.LOADING_BOOKING_SUCCESS:
            return {
                ...state,
                moduleId: [...action.module_id],
                bookingData: action.userBookingDetails,
                bookingLoading: false,
            }
        case bookingActionTypes.LOADING_BOOKING_ERROR:
            return {
                ...state,
                bookingLoading: false
            }

        case bookingActionTypes.CHECK_BOOKING:
            return {
                ...state,
                checkingLoading: true
            }
        case bookingActionTypes.CHECK_BOOKING_SUCCESS:
            return {
                ...state,
                bookingId: action.bookingId,
                checkingLoading: false
            }
        case bookingActionTypes.CHECK_BOOKING_ERROR:
            return {
                ...state,
                checkingLoading: false
            }
        case bookingActionTypes.REGISTER_GUEST_BOOKING:
            return {
                ...state,
                guestLoading: true
            }
        case bookingActionTypes.REGISTER_GUEST_BOOKING_SUCCESS:
            return {
                ...state,
                registerGuestInfo: action.bookingId,
                guestLoading: false
            }
        case bookingActionTypes.REGISTER_GUEST_BOOKING_ERROR:
            return {
                ...state,
                guestLoading: false
            }
        default:
            return state
    }

}   