/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarHalfAlt, faStar, faBolt } from '@fortawesome/free-solid-svg-icons';

import Food from '../../assets/images/food.jpg';
// import person from '../../assets/images/customers.jpg';


class ResturantsCard extends Component {
  render() {

    return (
      <div className="cards-layer">
          <Card>
            <a href="#" className="card-link-overlay"> <span className="hidden">link</span></a>
            <div className="card-icons">
                <a href="#" className="item-circle d-block text-center">
                <i className="fas fa-utensils"></i>
                </a>
                <a href="#" className="item-circle d-block text-center">
                <i className="far fa-heart"></i>
                </a>
            </div>
            <div className="card-ribbon ribbon-top-left">
            <span className="bg-warning text-center">
              <FontAwesomeIcon icon={faBolt} />
            </span>

          </div>
            <div className="card-img-wrap">
              <Card.Img variant="top" src={Food} />
              
              <div className="title-destination bg-white">
                <span>Resturants</span>
              </div>
            </div>
            
            <Card.Body>
              <div className="header-wrap d-flex">
                <Card.Title>Somik Resturants</Card.Title>
              </div>
              <Card.Text className="card-body-text">
              Lorem ipsum dolor sit amet, quis int nostrum exercitationem 
              </Card.Text>
              <div className="resturants-details mt-3">
                    <a href="#"><div className="circle mr-2"><i className="fas fa-map-marker-alt"></i></div> Florida, USA</a>
                    <a href="#"><div className="circle mr-2"><i className="fas fa-phone-alt"></i></div> +63 658-865-965</a>
                    <div className="time">
                        <div className="circle mr-2"><i className="fas fa-clock"></i></div> 10am - 9pm <span className="badge badge-danger ml-2">Open Now</span>
                    </div>
              </div>
            </Card.Body>
            <Card.Footer className="d-flex align-items-center">
            <div className="rating-wrap d-flex mb-3">
                <div className="rating-inner mr-2">
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStarHalfAlt} />
                  </div>
                  <span>(25 Reviews)</span>
              </div>
              <div className="love ml-auto">
               <p className="text-mute"> <i className="far fa-comment"></i> 49</p>
              </div>

            </Card.Footer>
          </Card>
        </div> 
    )
  }
}

export default ResturantsCard;
