import React, { useState, useEffect } from 'react';
// import Logo from '../../../assets/images/logo1.png';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import Logo from '../../../assets/images/logo.png';


import { checkAuth, logout } from '../../../store/actions/authActions/authActionCreators'
const baseURL = process.env.REACT_APP_BASE_URL

const Navbar = () => {
    const user = useSelector((state) => state.authReducer);
    const dispatch = useDispatch();

    const [isSideNavVisible, setIsSideNavVisible] = useState(false);
    // const [addClass, setaddClass] = useState('');
    const toggleNav = (e) => {
        setIsSideNavVisible(!isSideNavVisible)
    };

    const logoutHandler = () => {
        dispatch(logout())
    }

    useEffect(() => {
        dispatch(checkAuth());
    }, [dispatch]);

    useEffect(() => {
        // window.addEventListener('scroll', () => {
        //     let activeClass = 'sticky';
        //     if (window.scrollY === 0) {
        //         activeClass = 'no-sticky';
        //     }
        //     setaddClass({ activeClass });
        // });

    }, [])

    return (
        <section>
            <div className="page-header">
                <div className="container">
                    <div className="header-inner">
                        <nav className="navbar navbar-expand-xl navbar-light">
                            <div className="container-fluid">
                                <button className="btn sidenav-trigger d-xl-none" onClick={toggleNav}>
                                    <i className="fas fa-bars"></i>
                                </button>
                                <NavLink to="/" className="navbar-brand">
                                    <img src={Logo} alt="logo" className="img-fluid logo-color" />
                                    {/* <img src={Logo} alt="" className="img-fluid logo-white" /> */}

                                </NavLink>
                                <ul className="navbar-nav ml-auto align-items-center">
                                    <li className="nav-item custom-dropdown">
                                        <NavLink to="/" exact className="nav-link"> Home </NavLink>
                                    </li>
                                    {!user.isAuthenticated &&
                                        <li className="nav-item custom-dropdown">
                                            <NavLink to="/login" className="nav-link"> Log In</NavLink>{" "}
                                        </li>
                                    }
                                    {!user.isAuthenticated &&
                                        <li className="nav-item">
                                            <NavLink to="/register" className="nav-link">
                                                <button className="btn btn-primary"><i className="fas fa-plus pr-1"></i> Register</button>
                                            </NavLink>
                                        </li>

                                    }
                                    {user.isAuthenticated &&
                                        <li>
                                            <Dropdown>
                                                <Dropdown.Toggle id="dropdown-user" className="user-log">
                                                    <div className="d-flex align-items-center">
                                                        <div className="user-circle">
                                                            <img src={`${baseURL}${user.user.image}`} alt="user" className="img-fluid" />
                                                        </div>
                                                        <span><i className="fas fa-chevron-down"></i></span>
                                                    </div>

                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="custom-drops">
                                                    <Dropdown.Item onClick={logoutHandler}>logout</Dropdown.Item>

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>
                                    }
                                </ul>
                                <NavLink to="/" className="add-link-sm d-xl-none">
                                    <i className="fas fa-plus"></i>
                                </NavLink>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>

            {/* <div className={`sidebar-navigation ${isSideNavVisible === true ? '' : 'display'}`} id="pageSidebar">
            <button className="btn sidenav-trigger" onClick={toggleNav}>
                <i className="fas fa-times"></i>
            </button>
            <div className="sidebar-inner">
                <div className="accordion" id="accordionExample">
                    <div>
                        <div id="headingOne">
                            <h2 className="mb-0">
                                <button className="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    Home
                    </button>
                            </h2>
                        </div>

                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div>
                                Some placeholder content for the first accordion panel. This panel is shown by default, thanks to the <code>.show</code> className.
                </div>
                        </div>
                    </div>
                    <div>
                        <div id="headingTwo">
                            <h2 className="mb-0">
                                <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Category
                    </button>
                            </h2>
                        </div>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div>
                                Some placeholder content for the second accordion panel. This panel is hidden by default.
                </div>
                        </div>
                    </div>
                    <div>
                        <div id="headingThree">
                            <h2 className="mb-0">
                                <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Features
                    </button>
                            </h2>
                        </div>
                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div>
                                And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                </div>
                        </div>
                    </div>
                    <div>
                        <div id="headingFour">
                            <h2 className="mb-0">
                                <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Pages
                    </button>
                            </h2>
                        </div>
                        <div id="collapseFour" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div>
                                And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                </div>
                        </div>
                    </div>
                    <div>
                        <div id="headingFive">
                            <h2 className="mb-0">
                                <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Blogs
                    </button>
                            </h2>
                        </div>
                        <div id="collapseFive" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div>
                                And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                </div>
                        </div>
                    </div>
                    <button className="btn btn-block add-btn">
                        <i className="fas fa-plus" aria-hidden="true"></i> Add Post
        </button>
                </div>
            </div>
        </div>
    
     */}
        </section >

    )
}

export default Navbar;
