import * as authActionTypes from './../actions/authActions/authActionTypes'
const initState = {
    token: null,
    user: {},
    registerLoading: false,
    loginLoading: false,
    isAuthenticated:false,
    registerUser: {}
};

export const authReducer = (state = initState, action) => {
    switch (action.type) {
        case authActionTypes.LOADING_REGISTER:
            return {
                ...state,
                registerLoading: true,
            }
        case authActionTypes.LOADING_REGISTER_SUCCESS:
            return {
                ...state,
                registerUser: { ...action.userDetail },
                registerLoading: false,
            }
        case authActionTypes.LOADING_REGISTER_ERROR:
            return {
                ...state,
                registerLoading: false,
            }
        case authActionTypes.LOADING_LOGIN:
            return {
                ...state,
                loginLoading: true,
            }
        case authActionTypes.LOADING_LOGIN_SUCCESS:
            return {
                ...state,
                loginLoading: false,
                isAuthenticated:true,
                user: action.user,
                token: action.token,
            }
        case authActionTypes.LOADING_LOGIN_ERROR:
            return {
                ...state,
                loginLoading: false,
            }
            
        case authActionTypes.LOGOUT:
            return {
                ...state,
                isAuthenticated:false,
                user: null,
                token:null
            }
        default:
            return state
    }
}