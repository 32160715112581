/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Slider from "react-slick";
import CustomerCard from '../../../../component/Card/CustomerCard';


class Customer extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,

                  }
                },
              ]
        };
        return (
            // Section Customers Reviews
            <section className="ptb-48 bg-white">
                <div className="container">
                    <div className="customer-wrapper">
                        <div className="heading text-center pb-48">
                            <h3 >Our</h3>
                            <h2>Customers Says</h2>
                            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>

                        </div>
                        <div className="customer-slider hide-btn">
                            <Slider {...settings}>
                              <CustomerCard />
                              <CustomerCard />
                              <CustomerCard />
                              <CustomerCard />
                              <CustomerCard />
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
             // Section Customers Reviews  Ends
        )
    }
}

export default Customer;
