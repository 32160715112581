/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import {  Modal,Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay} from '@fortawesome/free-solid-svg-icons';

import Background from '../../../../assets/images/bg/pattern2.jpg';

class Video extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
           visible: false
        };
        
      };

    playVideoModal = () => {
        this.setState({
            visible: true,
        });
    };

    hideModal = () => {
        this.setState({
            visible: false,
        });
    };
    render() {

        return (
            // Video player
            <section>
                <div className="video-wrapper  bg-cover" style={{ background: `url(${Background}) center center` }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 d-block mx-auto">
                                <div className="wrapper-title text-center text-white">
                                    <h2 className="text-white mb-1">Great Adventure Tour in Austerlia </h2>
                                    <p className="mt-4">We denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
                                    <div className="play-video mt-4">
                                    <Button variant="primary"  className="text-white" onClick={this.playVideoModal}><FontAwesomeIcon icon={faPlay}/></Button>
                                        {/* <Button type="primary" className="text-white" onClick={playVideoModal}>
                                            <FontAwesomeIcon icon={faPlay}/>
                                        </Button> */}
                                        <Modal show={this.state.visible} onHide={this.handleClose}>
                                    <Modal.Header closeButton>
                                    <Modal.Title>Modal heading</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                                    <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Close
                                    </Button>
                                    </Modal.Footer>
                                </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            // Video player Ends
        )
    }
}

export default Video;
