import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFilter, searchByFilter } from '../../store/actions/hotelActions/hotelActionCreators';

import InputRange from 'react-input-range';
import FilterModal from './component/filterModal';

const FilterHotels = () => {

    const filterData = useSelector((state) => state.hotelReducer.filterHotel)
    const [searchDataList, setSearchDataList] = useState(JSON.parse(localStorage.getItem("searchItems")))

    const dispatch = useDispatch();
    const [priceRange, setPriceRange] = useState(0);
    const [hotelFacility, setHotelFacility] = useState([]);
    const [hotelInventories, setHotelInventories] = useState([]);
    const [hotelAmenities, setHotelAmenities] = useState([]);
    const [hotelFeature, setHotelFeature] = useState([]);
    const [hotelLandmark, setHotelLandmark] = useState([]);
    const [hotelInvMeal, setHotelInvMeal] = useState([]);
    const [hotelSafety, setHotelSafety] = useState();
    const [filteredModal, setFilteredModal] = useState(false)

    let previous_parameters = {
        "location": searchDataList.location,
        "number_of_adult": searchDataList.adults,
        "number_of_child": searchDataList.childrens,
        "number_of_infant": searchDataList.infants,
        "check_in_date": searchDataList.checkin,
        "check_out_date": searchDataList.checkout,
        "required_room": searchDataList.room
    };

    const showModal = () => {
        setFilteredModal(true)

    };

    const getFilterData = () => {
        const data = {
            'previous_parameters': previous_parameters,
        }
        dispatch(fetchFilter(data));
    }

    const onchangeHotelFacility = (id, e) => {
        if (e.target.checked) {
            let selectedFacility = hotelFacility;
            selectedFacility.push(id);
            setHotelFacility(selectedFacility);
            const data = {
                'previous_parameters': previous_parameters,
                'hotel_facilities': selectedFacility.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_facilities': hotelInventories.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_amenities': hotelAmenities.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_features': hotelFeature.map((id) => (
                    {
                        id: id
                    }
                )),
                'hotel_landmark': hotelLandmark.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_meal': hotelInvMeal.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_price': {
                    "lowest": filterData.inventory_price.lowest,
                    "highest": priceRange
                },
                'hotel_safety': {
                    'value': hotelSafety,
                },
            }
            showModal();
            dispatch(
                searchByFilter(data, (res, err) => {
                    if (res) {
                        setTimeout(() =>{
                            setFilteredModal(false)
                        }, 2000)
                    }
                   
                }))
        } else {
            const index = hotelFacility.findIndex(fId => fId === id)
            let selectedFacility = hotelFacility;
            selectedFacility.splice(index, 1);
            const data = {
                'previous_parameters': previous_parameters,
                'hotel_facilities': selectedFacility.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_facilities': hotelInventories.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_amenities': hotelAmenities.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_features': hotelFeature.map((id) => (
                    {
                        id: id
                    }
                )),
                'hotel_landmark': hotelLandmark.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_meal': hotelInvMeal.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_price': {
                    "lowest": filterData.inventory_price.lowest,
                    "highest": priceRange
                },
                'hotel_safety': {
                    'value': hotelSafety,
                },
            }
            showModal();
            dispatch(
                searchByFilter(data, (res, err) => {
                    if (res) {
                        setFilteredModal(false)
                    }
                   
                }))
        }
    }

    const handlePriceRange = (price) => {
        const data = {
            'previous_parameters': previous_parameters,
            'hotel_facilities': hotelFacility.map((id) => (
                {
                    id: id
                }
            )),
            'inventories_facilities': hotelInventories.map((id) => (
                {
                    id: id
                }
            )),
            'inventories_amenities': hotelAmenities.map((id) => (
                {
                    id: id
                }
            )),
            'inventories_features': hotelFeature.map((id) => (
                {
                    id: id
                }
            )),
            'hotel_landmark': hotelLandmark.map((id) => (
                {
                    id: id
                }
            )),
            'inventory_meal': hotelInvMeal.map((id) => (
                {
                    id: id
                }
            )),
            'inventory_price': {
                "lowest": filterData.inventory_price.lowest,
                "highest": price
            },
            'hotel_safety': {
                'value': hotelSafety,
            },
        }
        showModal();
        dispatch(
            searchByFilter(data, (res, err) => {
                if (res) {
                     setTimeout(() =>{
                            setFilteredModal(false)
                    }, 2000)
                }
            }))
    }

    const handleHotelInventories = (id, e) => {
        if (e.target.checked) {
            let selectedInventory = hotelInventories;
            selectedInventory.push(id);
            setHotelInventories(selectedInventory);
            const data = {
                'previous_parameters': previous_parameters,
                'hotel_facilities': hotelFacility.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_facilities': selectedInventory.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_amenities': hotelAmenities.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_features': hotelFeature.map((id) => (
                    {
                        id: id
                    }
                )),
                'hotel_landmark': hotelLandmark.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_meal': hotelInvMeal.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_price': {
                    "lowest": filterData.inventory_price.lowest,
                    "highest": priceRange
                },
                'hotel_safety': {
                    'value': hotelSafety,
                },
            }
            showModal();
            dispatch(
                searchByFilter(data, (res, err) => {
                    if (res) {
                        setFilteredModal(false)
                    }
                   
                }))
        } else {
            const index = hotelInventories.findIndex((fId => fId === id))
            let selectedInventory = hotelInventories;
            selectedInventory.splice(index, 1);
            const data = {
                'previous_parameters': previous_parameters,
                'hotel_facilities': hotelFacility.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_facilities': selectedInventory.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_amenities': hotelAmenities.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_features': hotelFeature.map((id) => (
                    {
                        id: id
                    }
                )),
                'hotel_landmark': hotelLandmark.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_meal': hotelInvMeal.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_price': {
                    "lowest": filterData.inventory_price.lowest,
                    "highest": priceRange
                },
                'hotel_safety': {
                    'value': hotelSafety,
                },
            }
            showModal();
            dispatch(
                searchByFilter(data, (res, err) => {
                    if (res) {
                        setFilteredModal(false)
                    }
                   
                }))
        }
    }

    const handleAmenities = (id, e) => {
        if (e.target.checked) {
            let selectedAmenity = hotelAmenities;
            selectedAmenity.push(id);
            setHotelAmenities(selectedAmenity);
            const data = {
                'previous_parameters': previous_parameters,
                'hotel_facilities': hotelFacility.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_facilities': hotelInventories.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_amenities': selectedAmenity.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_features': hotelFeature.map((id) => (
                    {
                        id: id
                    }
                )),
                'hotel_landmark': hotelLandmark.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_meal': hotelInvMeal.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_price': {
                    "lowest": filterData.inventory_price.lowest,
                    "highest": priceRange
                },
                'hotel_safety': {
                    'value': hotelSafety,
                },
            }
            showModal();
            dispatch(
                searchByFilter(data, (res, err) => {
                    if (res) {
                        setFilteredModal(false)
                    }
                   
                }))
        } else {
            const index = hotelAmenities.findIndex((fId => fId === id))
            let selectedAmenity = hotelAmenities;
            selectedAmenity.splice(index, 1);
            const data = {
                'previous_parameters': previous_parameters,
                'hotel_facilities': hotelFacility.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_facilities': hotelInventories.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_amenities': selectedAmenity.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_features': hotelFeature.map((id) => (
                    {
                        id: id
                    }
                )),
                'hotel_landmark': hotelLandmark.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_meal': hotelInvMeal.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_price': {
                    "lowest": filterData.inventory_price.lowest,
                    "highest": priceRange
                },
                'hotel_safety': {
                    'value': hotelSafety,
                },
            }
            showModal();
            dispatch(
                searchByFilter(data, (res, err) => {
                    if (res) {
                        setFilteredModal(false)
                    }
                   
                }))
        }
    }

    const handleInvFeature = (id, e) => {
        if (e.target.checked) {
            let selectedFeature = hotelFeature;
            selectedFeature.push(id);
            setHotelFeature(selectedFeature);
            const data = {
                'previous_parameters': previous_parameters,
                'hotel_facilities': hotelFacility.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_facilities': hotelInventories.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_amenities': hotelAmenities.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_features': selectedFeature.map((id) => (
                    {
                        id: id
                    }
                )),
                'hotel_landmark': hotelLandmark.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_meal': hotelInvMeal.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_price': {
                    "lowest": filterData.inventory_price.lowest,
                    "highest": priceRange
                },
                'hotel_safety': {
                    'value': hotelSafety,
                },
            }
            showModal();
            dispatch(
                searchByFilter(data, (res, err) => {
                    if (res) {
                        setFilteredModal(false)
                    }
                   
                }))
            } else {
            const index = hotelFeature.findIndex((fId => fId === id))
            let selectedFeature = hotelFeature;
            selectedFeature.splice(index, 1);
            const data = {
                'previous_parameters': previous_parameters,
                'hotel_facilities': hotelFacility.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_facilities': hotelInventories.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_amenities': hotelAmenities.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_features': selectedFeature.map((id) => (
                    {
                        id: id
                    }
                )),
                'hotel_landmark': hotelLandmark.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_meal': hotelInvMeal.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_price': {
                    "lowest": filterData.inventory_price.lowest,
                    "highest": priceRange
                },
                'hotel_safety': {
                    'value': hotelSafety,
                },
            }
            showModal();
            dispatch(
                searchByFilter(data, (res, err) => {
                    if (res) {
                        setFilteredModal(false)
                    }
                   
                }))
        }
    }

    const handleLandmark = (id, e) => {
        if (e.target.checked) {
            let selectedLandmark = hotelLandmark;
            selectedLandmark.push(id);
            setHotelLandmark(selectedLandmark);
            const data = {
                'previous_parameters': previous_parameters,
                'hotel_facilities': hotelFacility.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_facilities': hotelInventories.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_amenities': hotelAmenities.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_features': hotelFeature.map((id) => (
                    {
                        id: id
                    }
                )),
                'hotel_landmark': selectedLandmark.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_meal': hotelInvMeal.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_price': {
                    "lowest": filterData.inventory_price.lowest,
                    "highest": priceRange
                },
                'hotel_safety': {
                    'value': hotelSafety,
                },
            }
            showModal();
            dispatch(
                searchByFilter(data, (res, err) => {
                    if (res) {
                        setFilteredModal(false)
                    }
                   
                }))
        } else {
            const index = hotelLandmark.findIndex((fId => fId === id))
            let selectedLandmark = hotelLandmark;
            selectedLandmark.splice(index, 1);
            const data = {
                'previous_parameters': previous_parameters,
                'hotel_facilities': hotelFacility.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_facilities': hotelInventories.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_amenities': hotelAmenities.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_features': hotelFeature.map((id) => (
                    {
                        id: id
                    }
                )),
                'hotel_landmark': selectedLandmark.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_meal': hotelInvMeal.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_price': {
                    "lowest": filterData.inventory_price.lowest,
                    "highest": priceRange
                },
                'hotel_safety': {
                    'value': hotelSafety,
                },
            }
            showModal();
            dispatch(
                searchByFilter(data, (res, err) => {
                    if (res) {
                        setFilteredModal(false)
                    }
                   
                }))
        }
    }

    const handleInventoryMeal = (id, e) => {
        if (e.target.checked) {
            let selectedMeal = hotelInvMeal;
            selectedMeal.push(id);
            setHotelInvMeal(selectedMeal);
            const data = {
                'previous_parameters': previous_parameters,
                'hotel_facilities': hotelFacility.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_facilities': hotelInventories.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_amenities': hotelAmenities.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_features': hotelFeature.map((id) => (
                    {
                        id: id
                    }
                )),
                'hotel_landmark': hotelLandmark.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_meal': selectedMeal.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_price': {
                    "lowest": filterData.inventory_price.lowest,
                    "highest": priceRange
                },
                'hotel_safety': {
                    'value': hotelSafety,
                },
            }
            showModal();
            dispatch(
                searchByFilter(data, (res, err) => {
                    if (res) {
                        setFilteredModal(false)
                    }
                   
                }))
        } else {
            const index = hotelInvMeal.findIndex((fId => fId === id))
            let selectedMeal = hotelInvMeal;
            selectedMeal.splice(index, 1);
            const data = {
                'previous_parameters': previous_parameters,
                'hotel_facilities': hotelFacility.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_facilities': hotelInventories.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_amenities': hotelAmenities.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_features': hotelFeature.map((id) => (
                    {
                        id: id
                    }
                )),
                'hotel_landmark': hotelLandmark.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_meal': selectedMeal.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_price': {
                    "lowest": filterData.inventory_price.lowest,
                    "highest": priceRange
                },
                'hotel_safety': {
                    'value': hotelSafety,
                },
            }
            showModal();
            dispatch(
                searchByFilter(data, (res, err) => {
                    if (res) {
                        setFilteredModal(false)
                    }
                   
                }))
        }
    }

    const handleHotelSafety = (name, e) => {
        if (e.target.checked) {
            let selectedSafety;
            if (name === "Yes") {
                selectedSafety = "true";
            } else {
                selectedSafety = "false";
            }
            setHotelSafety(selectedSafety);
            const data = {
                'previous_parameters': previous_parameters,
                'hotel_facilities': hotelFacility.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_facilities': hotelInventories.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_amenities': hotelAmenities.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventories_features': hotelFeature.map((id) => (
                    {
                        id: id
                    }
                )),
                'hotel_landmark': hotelLandmark.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_meal': hotelInvMeal.map((id) => (
                    {
                        id: id
                    }
                )),
                'inventory_price': {
                    "lowest": filterData.inventory_price.lowest,
                    "highest": priceRange
                },
                'hotel_safety': {
                    'value': selectedSafety,
                },
            }
            showModal();
            dispatch(
                searchByFilter(data, (res, err) => {
                    if (res) {
                        setFilteredModal(false)
                    }
                   
                }))
        }
    }

    useEffect(() => {

        getFilterData();
    }, [])

    return (
        <div className="filter-card">
            <h3 className="mb-20">Filter by</h3>
            <div className="filter-outer-wrap">
                <div className="filter-wrap">
                    <h5>Hotel Facilities</h5>
                    <div className="filter-rating">
                        {
                            filterData && filterData.hotel_facilities?.map((facility, index) => {
                                return <Form.Group key={index} className="d-flex align-items-center">
                                    <Form.Check
                                        name="terms"
                                        label={facility.name}
                                        id={facility.id}
                                        onChange={onchangeHotelFacility.bind(null, facility.id)}
                                    />
                                    <span className="pl-2">({facility.count})</span>
                                </Form.Group>
                            })
                        }
                    </div>

                </div>
                <div className="filter-wrap">
                    <h5 className="mb-4">Price range</h5>
                    <div className="filter-price">
                        <Form>
                            <Form.Group controlId="formBasicRange">
                                <InputRange
                                    formatLabel={priceRange => `${priceRange}`}
                                    maxValue={filterData && Number(filterData.inventory_price.highest)}
                                    minValue={filterData && Number(filterData.inventory_price.lowest)}
                                    value={priceRange}
                                    onChange={(priceRange) => setPriceRange(priceRange)}
                                    onChangeComplete={handlePriceRange.bind(null, priceRange)}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                </div>
                <div className="filter-wrap">
                    <h5>Inventories Facilities</h5>
                    <div className="filter-rating">
                        {
                            filterData && filterData.inventories_facilities?.map((inventory, index) => {
                                return <Form.Group key={index} className="d-flex align-items-center">
                                    <Form.Check
                                        name="terms"
                                        label={inventory.name}
                                        id={inventory.id}
                                        onChange={handleHotelInventories.bind(null, inventory.id)}
                                    />
                                    <span className="pl-2">({inventory.count})</span>
                                </Form.Group>
                            })
                        }
                    </div>

                </div>
                <div className="filter-wrap">
                    <h5>Inventories Amenities</h5>
                    <div className="filter-rating">
                        {
                            filterData && filterData.inventories_amenities?.map((amenities, index) => {
                                return <Form.Group key={index} className="d-flex align-items-center">
                                    <Form.Check
                                        required
                                        name="terms"
                                        label={amenities.name}
                                        onChange={handleAmenities.bind(null, amenities.id)}
                                        id={amenities.id}
                                    />
                                    <span className="pl-2">({amenities.count})</span>
                                </Form.Group>
                            })
                        }
                    </div>

                </div>
                <div className="filter-wrap">
                    <h5>Inventories Feature</h5>
                    <div className="filter-rating">
                        {
                            filterData && filterData.inventories_features?.map((invFeature, index) => {
                                return <Form.Group key={index} className="d-flex align-items-center">
                                    <Form.Check
                                        required
                                        name="terms"
                                        label={invFeature.name}
                                        onChange={handleInvFeature.bind(null, invFeature.id)}
                                        id={invFeature.id}
                                    />
                                    <span className="pl-2">({invFeature.count})</span>
                                </Form.Group>
                            })
                        }
                    </div>

                </div>
                <div className="filter-wrap">
                    <h5>Hotel Landmark</h5>
                    <div className="filter-rating">
                        {
                            filterData && filterData.hotel_landmark?.map((landmark, index) => {
                                return <Form.Group key={index} className="d-flex align-items-center">
                                    <Form.Check
                                        required
                                        name="terms"
                                        label={landmark.name}
                                        onChange={handleLandmark.bind(null, landmark.id)}
                                        id={landmark.id}
                                    />
                                    <span className="pl-2">({landmark.count})</span>
                                </Form.Group>
                            })
                        }
                    </div>

                </div>
                <div className="filter-wrap">
                    <h5>Inventory Meal</h5>
                    <div className="filter-rating">
                        {
                            filterData && filterData.inventory_meal?.map((invmeal, index) => {
                                return <Form.Group key={index} className="d-flex align-items-center">
                                    <Form.Check
                                        required
                                        name="terms"
                                        label={invmeal.name}
                                        onChange={handleInventoryMeal.bind(null, invmeal.id)}
                                        id={invmeal.id}
                                        feedbackTooltip
                                    />
                                    <span className="pl-2">({invmeal.count})</span>
                                </Form.Group>
                            })
                        }
                    </div>

                </div>
                <div className="filter-wrap">
                    <h5>Hotel Safety</h5>
                    <div className="accomation-wrap">
                        {filterData && filterData.hotel_safety.map((safety, index) =>
                            <div key={index}>
                                <Form.Check
                                    type="radio"
                                    label={safety.name}
                                    name="hotelSafety"
                                    id={safety.id}
                                    onChange={handleHotelSafety.bind(null, safety.name)}
                                />
                            </div>
                        )}

                    </div>
                </div>

            </div>
            <FilterModal show={filteredModal} />
        </div>
    )
}


export default FilterHotels;