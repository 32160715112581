import * as locationActionTypes from '../actions/locationActions/locationActionType';

const initState = {
    locations: null,
    keywordsloading: false,
    currentPage: null,
    totalCount: null,
}



export const locationReducer = (state = initState, action) => {
    switch (action.type) {
        case locationActionTypes.LOCATION_LOADING:
            return {
                ...state,
                keywordsloading: true,
            }
        case locationActionTypes.LOCATION_LOADING_SUCCESS:
            return {
                ...state,
                locations:action.data,
                currentPage: action.currentPage,
                totalCount: action.total,
                keywordsloading: false,
                
            }
        case locationActionTypes.LOCATION_LOADING_ERROR:
            return {
                ...state,
                keywordsloading: false,
            }
        default:
            return state

    }
}