import { combineReducers } from "redux";
import { authReducer } from "./authReducers";
import { hotelReducer } from "./hotelReducers"
import { locationReducer } from "./locationReducer"
import { bookingReducer } from "./bookingReducer"
import { paymentReducer } from "./paymentReducer"

export const rootReducer = combineReducers({
  authReducer,
  hotelReducer,
  locationReducer,
  bookingReducer,
  paymentReducer,
});
