import React from 'react'
// import { useSelector } from "react-redux";

import {Modal, Spinner} from 'react-bootstrap'

const FilterModal =(props) =>{
    // const filter = useSelector(state => state.hotelReducer);
    return (
        <Modal show={props.show}  centered >
        <Modal.Body className="text-center p-4">
             
            <Spinner animation="border" variant="warning" />
            <p className="mt-2">Book Hotel Now</p> 
        </Modal.Body>
      </Modal>
    )
}

export default  FilterModal;