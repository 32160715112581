/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Customer from '../../assets/images/customers.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarHalfAlt, faStar } from '@fortawesome/free-solid-svg-icons';

class CustomerCard extends Component {
    render() {
        return (
            <div className="card-item mt-2 mb-4">
                <div className="card">
                    <div className="card-side bg-primary-transparent"></div>
                    <div className="card-body">
                        <div className="card-body-inner d-flex">
                            <div className="customer-avatar mr-3">
                                <img src={Customer} className="img-fluid" alt="logo" />
                            </div>
                            <div className="customer-reviews mt-4 mt-md-0">
                                <h3>Elizabeth</h3>
                                <div className="rating-inner mt-1 mb-3">
                                    <FontAwesomeIcon icon={faStar} />
                                    <FontAwesomeIcon icon={faStar} />
                                    <FontAwesomeIcon icon={faStar} />
                                    <FontAwesomeIcon icon={faStar} />
                                    <FontAwesomeIcon icon={faStarHalfAlt} />
                                </div>
                                <p className="mt-2"> Lorem ipsum dolor consectetur adipisicing elit. Quod eos id officiis hic </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default CustomerCard;
