/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import Navbar from '../component/Common/Navbar/Navbar';
import Footer from '../component/Common/Footer/Footer';
import Home from '../pages/Home/home.js';
import SearchResult from '../pages/SearchResult/SearchResult';
import Booking from '../pages/Booking/booking';
import Details from '../component/Detail/Detail';
import './../App.scss';
import Hotel from '../pages/Hotel/hotel';
import Register from '../pages/auth/register/register';
import Login from '../pages/auth/login/login';


const MasterRoutes = () =>{
    const location = useLocation();
    const [wrapperClass, setWrapperClass] = useState(['main-wrapper'])

    useEffect(() => {
        if (location.pathname === '/searchresult' || location.pathname === '/booking' || location.pathname.indexOf('/hotel') > -1) {
            setWrapperClass([...wrapperClass, 'searchWrapper'])
        }
    }, [location])

    return (
        <div className={wrapperClass.join(' ')}>
            <Navbar />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/details" component={Details} />
                <Route path="/searchresult" component={SearchResult} />
                <Route path="/hotel/:id" component={Hotel} />
                <Route path="/booking" component={Booking} />
                <Route path="/register" component={Register} />
                <Route path="/login" component={Login} />
            </Switch>
            <Footer />
        </div>

    )
}


export default MasterRoutes;