/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Place from '../../assets/images/package.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons';


class TourPackageCard extends Component {
    render() {
        return (
            <div className="col-lg-4 col-md-6">
                <div className="card mb-3">
                    <a href="#" className="card-link-overlay"> <span className="hidden">link</span></a>
                    <div className="card-img">
                        <img src={Place} alt="travel" className="img-fluid" />
                        <div className="card-img-header p-3">
                            <h4 className="text-white">Historical Tour</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="package-details">
                            <a href="#" className="text-dark d-flex">
                                <h4 className="mb-1 ">Canada Historical Tour</h4>
                                <div className="ml-auto">
                                    <div className="price">$86 <small className="day">/ Day</small></div>
                                </div> 
                            </a> 
                            <p className="ticket">Flight Tickets Not Included <sup className="text-danger">*</sup></p>
                            <small className="badge bg-light"> <FontAwesomeIcon icon={faClock} className="mr-1"/> 5 Days 4 Nights Trip</small>
                            <small className="badge bg-light ml-1"> <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1"/>10 Places</small>
                        </div>
                    </div>
                    <div className="card-body bt">
                        <div className="package-details">
                            <a href="#" className="text-dark d-flex">
                                <h4 className="mb-1 ">Austerlia Historical Tour</h4>
                                <div className="ml-auto">
                                    <div className="price">$85 <small className="day">/ Day</small></div>
                                </div> 
                            </a> 
                            <p className="ticket">Flight Tickets Not Included <sup className="text-danger">*</sup></p>
                            <small className="badge bg-light"> <FontAwesomeIcon icon={faClock} className="mr-1"/> 6 Days 5 Nights Trip</small>
                            <small className="badge bg-light ml-1"> <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1"/>10 Places</small>
                        </div>
                    </div>
                    <div className="card-body bt">
                        <div className="package-details">
                            <a href="#" className="text-dark d-flex">
                                <h4 className="mb-1 ">India Historical Tour</h4>
                                <div className="ml-auto">
                                    <div className="price">$66 <small className="day">/ Day</small></div>
                                </div> 
                            </a> 
                            <p className="ticket">Flight Tickets Not Included <sup className="text-danger">*</sup></p>
                            <small className="badge bg-light"> <FontAwesomeIcon icon={faClock} className="mr-1"/> 15 Days 14 Nights Trip</small>
                            <small className="badge bg-light ml-1"> <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1"/>10 Places</small>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TourPackageCard;
