/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import Slider from "react-slick";
import ResturantsCard from '../../../../component/Card/ResturantsCard';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        />
    );
}

class Resturants extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            autoplay: false,
            autoplaySpeed: 8000,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,

                  }
                },
              ]
        };
        return (
            // Section  Resturants 
            <section className="ptb-48 bg-white">
                <div className="container">
                    <div className="resturants-wrapper">
                        <div className="heading text-center pb-48">
                            <h3 >Near By</h3>
                            <h2>Resturents and Hotels</h2>
                            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>

                        </div>
                        <Slider {...settings}>
                            <ResturantsCard />
                            <ResturantsCard />
                            <ResturantsCard />
                            <ResturantsCard />
 
                        </Slider>
                    </div>
                </div>

            </section>
            // End of Section  Resturants 
        )
    }
}

export default Resturants;
