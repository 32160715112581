import * as paymentActionTypes from '../actions/paymentActions/paymentActionTypes';

const initState = {
    payment: null,
    totalCount: null,
    paymentLoading: false,
    points:{},
    pointsLoading:false,
}



export const paymentReducer = (state = initState, action) => {
    switch (action.type) {
        case paymentActionTypes.LOADING_STORE_PAYMENT:
            return {
                ...state,
                paymentLoading: true,
            }
        case paymentActionTypes.LOADING_STORE_PAYMENT_SUCCESS:
            return {
                ...state,
                payment: action.storePayment,
                paymentLoading: false,

            }
        case paymentActionTypes.LOADING_STORE_PAYMENT_ERROR:
            return {
                ...state,
                paymentLoading: false,
            }
        case paymentActionTypes.LOADING_CHECK_POINTS:
            return {
                ...state,
                pointsLoading: true,
            }
        case paymentActionTypes.LOADING_CHECK_POINTS_SUCCESS:
            return {
                ...state,
                points: action.points,
                pointsLoading: false,

            }
        case paymentActionTypes.LOADING_CHECK_POINTS_ERROR:
            return {
                ...state,
                pointsLoading: false,
            }
        default:
            return state

    }
}