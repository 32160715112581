import React from 'react'

import { Modal, Button } from 'react-bootstrap';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
// import L from 'leaflet';
import {Icon} from 'leaflet';

const hotelMarker = new Icon({
  iconUrl: 'https://www.realestate-valdeurope.com/wp-content/themes/red/images/marker/icon-hotel.svg',
  iconSize: [50, 50]
})


export const MapModalView = (props) => {

  return (
    <Modal show={props.show} onHide={props.hide} centered={true} size="lg">
      <Modal.Body>
        <div className="maps-wrappers">
          <MapContainer center={[props.latitude, props.longitude]} zoom={13} scrollWheelZoom={true}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[props.latitude, props.longitude]} icon={hotelMarker}>
              <Popup> A pretty CSS3 popup. <br /> Easily customizable. </Popup>
            </Marker>
          </MapContainer>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.hide}>
          Close
          </Button>
        <Button variant="primary">
          Save Changes
          </Button>
      </Modal.Footer>
    </Modal>
  )
}
  ;

export default MapModalView;