
import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import locationImg from '../../assets/images/icon/location.svg';
import dateImg from '../../assets/images/icon/calender.svg';
import roomImg from '../../assets/images/icon/room.svg';
import person from '../../assets/images/icon/user.svg';
import { getLocations} from '../../store/actions/locationActions/locationActionCreators'
import { getHotels} from '../../store/actions/hotelActions/hotelActionCreators'
import { useDispatch, useSelector } from 'react-redux';

const SearchPanel = ({ searchList }) => {
    const location = useSelector(state => state.locationReducer)
    const dispatch = useDispatch();
    const history = useHistory();

    const [searchlocation, setSearchLocation] = useState('');
    const [room, setRoom] = useState(1);
    const [cityList, setCityList] = useState([]);
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [countAdults, setCountAdults] = useState(0);
    const [countChildren, setCountChildren] = useState(0);
    const [countInfant, setCountInfant] = useState(0);
    const [locationPop, setLocationPop] = useState(false)
    const [guestPop, setGuestPop] = useState(false)

    const handleLocationSearchChange = (e) => {
        setSearchLocation(e.target.value)
        dispatch(
            getLocations(e.target.value, (res, err) =>{
                if(res){ 
                    setCityList(res.data.keywords.data)
                }
            })
        )
       
    }

    const roomHandler = (e) => {
        setRoom(e.target.value);
    }
    const handleEvent = (event) => {
        setDateRange(event)
    }

    const searchHotelHandler = () =>{
        const data =  {
            location: searchlocation,
            checkin: dateRange[0],
            checkout: dateRange[1],
            room: room,
            adults: countAdults,
            childrens: countChildren,
            infants: countInfant }
        dispatch(getHotels(data, (res, err) => {
            if(res){
                history.push('/searchresult')
            }
            if(err){
                console.log(err);
            }
       }))
    }

    useEffect(() => {
        if (searchList) {
            setSearchLocation(searchList.location)
            setDateRange([searchList.checkin, searchList.checkout])
            setRoom(searchList.room)
            setCountAdults(searchList.adults)
            setCountChildren(searchList.childrens)
            setCountInfant(searchList.infants)
        }
    }, [searchList])

    return (
        <div className="banner-search" onClick={() => {
            setLocationPop(false)
            setGuestPop(false)
        }
        }>
            <Form className="row m-0">
                <Form.Row>
                    <Form.Group as={Col} xs="12">
                        <Form.Label>Places</Form.Label>
                        <div className="form-group-outer">
                            <div className="form-group-wrap">
                                <Form.Control type="text" placeholder="Current Location"
                                    value={searchlocation}
                                    onChange={handleLocationSearchChange}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setLocationPop(true)
                                    }} />
                                <div className="icon-append">
                                    <img src={locationImg} className="img-fluid" alt="location" />
                                </div>
                            </div>
                            {locationPop && <div className="popup-wrappers">
                                
                                <ul className="popup-wrappers-inner">
                                    {cityList.length > 0 ? cityList.map((data, index) =>
                                
                                        <li key={index} onClick={() => setSearchLocation(data.name)}>
                                            <span className="pr-2"><i className="fas fa-map-marker-alt"></i></span>{data.name}</li>)
                                        : <li className="text-center">{location.keywordsloading ? 
                                             <Spinner animation="border" size="sm" variant="warning" /> : 
                                            'Location not Found'
                                            }</li>
                                
                                    }
                                </ul>
                            </div>}
                        </div>

                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} xs="12">
                        <Form.Label>Checkin - Checkout</Form.Label>
                        <div className="form-group-wrap">
                            <DateRangePicker
                                onChange={handleEvent}
                                value={dateRange} 
                                className="form-control"
                            />
                            <div className="icon-append">
                                <img src={dateImg} className="img-fluid" alt="calender" />
                            </div>
                        </div>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} xs="3">
                        <Form.Label>Room</Form.Label>
                        <div className="form-group-wrap">
                            <Form.Control type="text" placeholder="Room" value={room} onChange={roomHandler} />
                            <div className="icon-append">
                                <img src={roomImg} className="img-fluid" alt="Room" />
                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group as={Col} xs="9">
                        <Form.Label>Guests</Form.Label>
                        <div className="form-group-outer">
                            <div className="form-group-wrap">
                                <Form.Control type="text"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setGuestPop(true)
                                    }} />
                                <div className="text-append" onClick={(e) => {
                                        e.stopPropagation()
                                        setGuestPop(true)
                                    }} >
                                    <span className="pr-2">{countAdults} Adults</span>
                                    <span className="pr-2">{countChildren} Children</span>
                                    <span>{countInfant} Infant</span>
                                </div>
                                <div className="icon-append">
                                    <img src={person} className="img-fluid" alt="person" />
                                </div>
                            </div>
                            {guestPop && <div className="popup-wrappers">
                                <ul>
                                    <li className="guest-item-wrap d-flex align-items-center">
                                        <label>Adults</label>
                                        <div className="input-wrap d-flex align-items-center ml-auto">
                                            <p className="btn btn-outline" onClick={(e) => {
                                                e.stopPropagation()
                                                if (countAdults === 0) {
                                                    return
                                                } else {
                                                    setCountAdults(countAdults - 1)
                                                }
                                            }}>-</p>

                                            <div className="count-value px-3 py-2">{countAdults}</div>

                                            <p className="btn btn-outline" onClick={(e) => {
                                                e.stopPropagation()
                                                if (countAdults === 30) {
                                                    return
                                                } else {
                                                    setCountAdults(countAdults + 1)
                                                }
                                            }}>+</p>
                                        </div>
                                    </li>
                                    <li className="guest-item-wrap d-flex align-items-center">
                                        <label>Child</label>
                                        <div className="input-wrap d-flex align-items-center ml-auto">
                                            <p className="btn btn-outline" onClick={(e) => {
                                                e.stopPropagation()
                                                if (countChildren === 0) {
                                                    return
                                                } else {
                                                    setCountChildren(countChildren - 1)
                                                }
                                            }}>-</p>
                                            <div className="count-value px-3 py-2">{countChildren}</div>
                                            <p className="btn btn-outline" onClick={(e) => {
                                                e.stopPropagation()
                                                if (countChildren === 10) {
                                                    return
                                                } else {
                                                    setCountChildren(countChildren + 1)
                                                }

                                            }}>+</p>
                                        </div>
                                    </li>
                                    <li className="guest-item-wrap d-flex align-items-center">
                                        <label>Infant</label>
                                        <div className="input-wrap d-flex align-items-center ml-auto">
                                            <p className="btn btn-outline" onClick={(e) => {
                                                e.stopPropagation()
                                                if (countInfant === 0) {
                                                    return
                                                } else {
                                                    setCountInfant(countInfant - 1)
                                                }
                                            }}>-</p>
                                            <div className="count-value px-3 py-2">{countInfant}</div>
                                            <p className="btn btn-outline" onClick={(e) => {
                                                e.stopPropagation()
                                                if (countInfant === 10) {
                                                    return
                                                } else {
                                                    setCountInfant(countInfant + 1)
                                                }
                                            }}>+</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            }
                        </div>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} xs="12" className="form-group-wrap mt-3 mb-0">
                            <Button variant="secondary" className="h-100 w-100" onClick={searchHotelHandler}>Search</Button>
                    </Form.Group>
                </Form.Row>
            </Form>
        </div>
    )
}

export default SearchPanel

