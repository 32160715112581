/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Slider from "react-slick";
import LocationCard from '../../../../component/Card/LocationCard';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        />
    );
}

class Location extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            autoplay: false,
            autoplaySpeed: 8000,
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                  breakpoint: 1300,
                  settings: {
                    slidesToShow: 2,
                  }
                },
                {
                  breakpoint: 700,
                  settings: {
                    slidesToShow: 1,

                  }
                },
              ]
        };
        return (
            // Section  Rated Locations
            <section className="ptb-48 bg-white">
                <div className="container">
                    <div className="rated-location-wrapper">
                        <div className="heading text-center pb-48">
                            <h3 >Best Top</h3>
                            <h2>Rated Flights</h2>
                            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>

                        </div>
                        <div className="rated-slider show-hide-btn">
                            <Slider {...settings}>
                              <LocationCard />
                              <LocationCard />
                              <LocationCard />
                              <LocationCard />
                              <LocationCard />
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
             // Section  location  Ends
        )
    }
}

export default Location;
