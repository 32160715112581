export const LOADING_BOOKING = "LOADING_BOOKING";
export const LOADING_BOOKING_SUCCESS = "LOADING_BOOKING_SUCCESS";
export const LOADING_BOOKING_ERROR = "LOADING_BOOKING_ERROR";

export const CHECK_BOOKING = "CHECK_BOOKING";
export const CHECK_BOOKING_SUCCESS = "CHECK_BOOKING_SUCCESS";
export const CHECK_BOOKING_ERROR = "CHECK_BOOKING_ERROR";


export const REGISTER_GUEST_BOOKING = "REGISTER_GUEST_BOOKING";
export const REGISTER_GUEST_BOOKING_SUCCESS = "REGISTER_GUEST_BOOKING_SUCCESS";
export const REGISTER_GUEST_BOOKING_ERROR = "REGISTER_GUEST_BOOKING_ERROR";