import * as authActionTypes from "./authActionTypes";
import axios from '../../../helpers/axios';

export const registerUser = (user, cb) =>{
    const formData = new FormData();
    formData.append('email', user.email)
    formData.append('password', user.password)
    return async(dispatch) =>{
        try{
            dispatch({
                type: authActionTypes.LOADING_REGISTER
                }
            );
            const response = await axios.post('/register_front_end_user/', formData);
            console.log("response.status", response.status)
            if(response.status === 201){
                dispatch({
                    type: authActionTypes.LOADING_REGISTER_SUCCESS,
                    user: response.data,
                })
                cb(true, null);
            }else{ 
                dispatch({
                    type: authActionTypes.LOADING_REGISTER_ERROR
                })
                cb(null, true);
            }
        }catch(err){
            dispatch({
                type: authActionTypes.LOADING_REGISTER_ERROR
            });
            cb(null, err);
        }
    }
}

export const loginUser = (user, cb) =>{
    return async(dispatch) =>{
        try{
            dispatch({
                type: authActionTypes.LOADING_LOGIN
            });
            const formData = new FormData();
            formData.append('email', user.email)
            formData.append('password', user.password)
            const response = await axios.post(`/login_front_end_user/`, formData);
            if(response.status === 200){
                dispatch({
                    type: authActionTypes.LOADING_LOGIN_SUCCESS,
                    token: response.data.token,
                    user: response.data,
                });
                window.localStorage.setItem("accessToken", response.data.token);
                window.localStorage.setItem("auth", JSON.stringify(response));
                cb(true, null);
            }else{ 
                dispatch({
                    type: authActionTypes.LOADING_LOGIN_ERROR
                });
                cb(null, true);
            }
        }catch(err){ 
            dispatch({
                type: authActionTypes.LOADING_LOGIN_ERROR
            });
            cb(null, err);
        }
    }
}   

export const checkAuth = () => {
    return (dispatch) => {
      const token = localStorage.getItem("accessToken");
      const auth = localStorage.getItem("auth");
      if (token) {
        dispatch({
          type: authActionTypes.LOADING_LOGIN_SUCCESS,
          token: token,
          user: JSON.parse(auth).data,
        });
      }
    };
  };

  export const logout = () => (dispatch) => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("auth");
    dispatch({
      type: authActionTypes.LOGOUT,
    });
  }
  